import React, { useEffect, useMemo, useState } from 'react';
import Platform from 'utils/Platform.js';
import ApiFacade from 'ApiFacade.js';
import JWTUtils from 'utils/JWT_Utils.js';
import Images from 'utils/Images.js';
import Content from 'utils/Content.js';
import IncompleteOffer from 'components/offers/Incomplete_Offer.js';

const configuration = require('configuration.json');
const clickthroughSourceUIPage = configuration.clickthrough_source_ui_page;


function CashbackOffersList() {

    const [state, setState] = useState({
        error: null,
        isLoaded: false,
        offers: []
    });
    
    const [filterByText, setText] = useState('');
    const [selectedCategoryID, setCategoryID] = useState(null);

    const cashbackCategories = useMemo(() => ({
        1: Content.getValue("cashback_categories")["1"],
        2: Content.getValue("cashback_categories")["2"],
        3: Content.getValue("cashback_categories")["3"],
        4: Content.getValue("cashback_categories")["4"],
        5: Content.getValue("cashback_categories")["5"],
    }), []);

    const imageSizes = useMemo(() => ({
        1: {width: 70, height: 110},
        2: {width: 130, height: 56},
        3: {width: 80, height: 100},
        4: {width: 80, height: 100},
        5: {width: 70, height: 110}
    }), []);

    const sourceUIPage = clickthroughSourceUIPage.cashback;

    const generateOffersList = (offers) => {

        let filteredOffers=[];

        if (filterByText) {

            filteredOffers = offers.filter(({ cashbackCategoryID, description, appName }) => {

                const lowerDescription = description.toLowerCase().replaceAll(" ","");
                const lowerAppName = appName.toLowerCase().replaceAll(" ","");
                const lowerText = filterByText.toLowerCase().replaceAll(" ","");

                const matchedSearch = lowerDescription.includes(lowerText) || lowerAppName.includes(lowerText);

                return selectedCategoryID ? 
                      matchedSearch && (cashbackCategoryID === selectedCategoryID)
                    : matchedSearch;
            });
        }
        else if (selectedCategoryID) {
            filteredOffers = offers.filter(({ cashbackCategoryID }) => (selectedCategoryID === cashbackCategoryID));
        }
        
        if (filteredOffers.length === 0) {
            return null;
        }

        return (
            <div>  
                      
            {filteredOffers.map(offer => (
            <IncompleteOffer 
                key={`_offer-cashback-${offer.ID}_${Date.now()}` + Math.random()} 
                offer={offer} 
                sourceUIPage={sourceUIPage}
            />
            ))}
            </div>
        );
    }

    const handleArrowClick = categoryID => {
        setCategoryID(parseInt(categoryID));
    }

    useEffect(() => {

        const platform = Platform.getPlatformName();
        let preferredLanguage = Content.getPreferredLanguage();
        let currentCountry = JWTUtils.getJWTCountry();

        const req = ApiFacade.offers(preferredLanguage, currentCountry, platform, 1);

        req.
        then(function (res) {

            if (res.ok){

                let result = res.response;
                setState({
                    isLoaded: true,
                    offers: result.apps
                });
            } else {

                setState({
                    isLoaded: true,
                    error: res.error
                });
            }
        },
        (error) => {
            
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
    }, []);

    return (
        <>
            <div className='cashback-search'>
                <img 
                    src={Images.imageURL('cashback-card-logo.png')} 
                    width="24" 
                    height="24" 
                    alt=""
                />
                <input 
                    className='cashback-input'
                    type='text'
                    placeholder={Content.getValue("i_want_to")}
                    value={filterByText}
                    onChange={({ target: { value } }) => setText(value)}
                />
            </div> 
    
            <div className="desktop list-offers-div-wrapper cashback-wrapper playground w-full">
                <div className="cashback desktop playground">
                    <div className={`providers-homepage-menu-container cashback desktop playground ${selectedCategoryID ? "selected" : ""}`}>
                        {!filterByText && (
                            <div className="menu-container desktop playground">
                                {Object.entries(cashbackCategories).map((entry) => {
                                    let [index, categoryItem] = entry;
                                    return (selectedCategoryID === parseInt(index) || !selectedCategoryID) && (
                                        <React.Fragment key={`category-${categoryItem}-${index}`}>
                                            {selectedCategoryID && (
                                                <p className='cashback-subtitle menu'>
                                                    {Content.getValue("category")}
                                                    <img
                                                        src={Images.imageURL('providers-homepage-menu-arrow.png')}
                                                        onClick={() => setCategoryID(null)}
                                                        width="30"
                                                        height="20"
                                                        alt="arrow"
                                                    />
                                                </p>
                                            )}
                                            <div className="providers-homepage-menu desktop cashback playground">
                                                <img
                                                    src={Images.imageURL(`providers-homepage-menu-${index}.png`)}
                                                    alt={`${categoryItem} logo`}
                                                    width={imageSizes[index]?.width || 'auto'}
                                                    height={imageSizes[index]?.height || 'auto'}
                                                />
                                                <img
                                                    src={Images.imageURL('providers-homepage-menu-arrow.png')}
                                                    onClick={() => handleArrowClick(index)}
                                                    width="30"
                                                    height="20"
                                                    alt="arrow"
                                                />
                                                <h2 className="providers-homepage-menu-description">
                                                    {cashbackCategories[index]}
                                                </h2>
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        )}
                    </div>
    
                    {state.offers?.length > 0 && (
                        <>
                            <div className="offers-div playground">
                                {(filterByText || selectedCategoryID) && (
                                    <p className='cashback-subtitle'>
                                        {Content.getValue("options")}
                                    </p>
                                )}
                                {state.offers.map(item => (
                                    <div 
                                        key={`app-${item.appName}`} 
                                        id={`${item.appName.replaceAll(" ", "-").toLowerCase()}-section`}
                                    >
                                        {generateOffersList(item.offers)}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default CashbackOffersList;