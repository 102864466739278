import Images from 'utils/Images';
import Joining from 'utils/Joining';
import CompletedLayout from 'components/offers/Completed_Layout';
import { useEffect } from 'react';
import { OfferPopupContext } from 'context/Offer_Popup_Context.js';
import { useContext } from 'react';
import Content from 'utils/Content.js';
import { useNavigate } from 'react-router-dom';
import Formatting from 'utils/Formatting.js';

const configuration = require("configuration.json");

const offerMainType = configuration.offer_main_type;

function TopOfferHomepageCard(props) {

  const navigate = useNavigate();
  const { handleOfferIsOpened } = useContext(OfferPopupContext);

  const offer = props.offer;
  const sourceUIPage = props.sourceUIPage;

  const getImageDimensions = (url) => {
    const img = new Image();
    img.src = url;
  };
  const {
    uniqueID,
    imageURL,
    coins,
    treatCoinValue,
    authorized,
    isCompleted,
    CategoryImageURL,
    CategoryHPID,
    MainTitle,
    reviewSubmitted,
    reviewAllowed,
    reviewRequired,
    isGold,
    isPercentage,
    hasLinkedOffers,
    CoinsUpTo,
    appName,
  } = offer;

  useEffect(() => {
    getImageDimensions(imageURL);
  }, [imageURL])
  return (
    <li key={uniqueID} className={`top-offer-homepage-card ${isGold ? "gold-box-shadow" : ""}`}>
      {isGold && (
          <span className='gold-offer-badge-wrap'>
            <span className='gold-offer-badge'>{Content.getValue('gold_offer_badge')}</span>
          </span>
      )}
      <CompletedLayout
        isPercentage={isPercentage}
        isHomepage
        coins={coins} treatCoinValue={treatCoinValue} isCompleted={isCompleted}
        reviewData={{
          reviewSubmitted: Boolean(reviewSubmitted),
          reviewAllowed: Boolean(reviewAllowed),
          reviewRequired: Boolean(reviewRequired),
        }}
      />
      <button className='hidden-button flex-column justify-between' onClick={() => {
        if (!authorized) {
          return Joining.showPopup(navigate);
        } else if(!isCompleted){
          handleOfferIsOpened({offer: offer, sourceUIPage})
        }
      }}>
      <div className="top-offer-homepage-first-part">
          {CategoryImageURL && CategoryHPID && !isGold && (
              <div className="top-offer-homepage-category">
                  <img className='top-offer-homepage-category-img' src={Images.imageURL(CategoryImageURL)} alt="Category" />
                  <p className="top-offer-homepage-category-desc">{Content.getValue(`additional_offers_heading_${offerMainType[CategoryHPID]}`)}</p>
              </div>
          )}
          <div
            style={{
              backgroundImage: `url(${imageURL})`,
            }}
            className='top-offer-homepage-image '
            alt="Top-Offer" >
          </div>
          <p className='top-offer-homepage-tc-value opacity-8'>{appName}</p>
          <p className='top-offer-homepage-desc'>{Formatting.shortenText(MainTitle, 100)}</p>
        </div>
        <div className="offer-table-container">
          <table className="top-offer-table">
            <tbody>
              {CoinsUpTo ? (
                <tr>
                  <td colSpan="3" className="top-offer-homepage-up-to">
                    {Content.getValue('top_offers_up_to')}
                  </td>
                </tr>
              ):<></>}

              <tr>
                <td className="top-offer-coin">
                  <img
                    width={20}
                    height={20}
                    src={Images.imageURL('offers/stats-header-money-icon-new.png')}
                    alt="TreatCoin"
                    className="top-offer-homepage-treat-coin"
                  />
                </td>

                <td className="offer-details">
                  <p className="top-offer-homepage-tc-value">
                    {CoinsUpTo ? CoinsUpTo.toLocaleString() : coins.toLocaleString()}
                  </p>
                </td>
                <td className='top-offer-homepage-usd-value text-12-i'>= </td>
                <td>
                  <p className="top-offer-homepage-usd-value text-12-i">
                    {Formatting.currencySymbol()}
                    {((CoinsUpTo ? CoinsUpTo : coins) * treatCoinValue).toFixed(2)}
                  </p>
                </td>
              </tr>
              <tr className='top-offer-homepage-currencies'>
                <td></td>
                <td className='top-offer-homepage-card-usd-desc'>
                  <p>{Content.getValue('treat_coins')}</p>
                </td>
                <td></td>
                <td className='top-offer-homepage-card-tc-desc'>
                  <p>
                    {Formatting.currencyLabel()}
                  </p>
                </td>
              </tr>

              {hasLinkedOffers ? (
                <tr>
                  <td colSpan="3" className="offer-linked">
                    <div className="top-offer-homepage-linked-offer">
                      <img src={Images.imageURL('offers/linked-offer.png')} alt="Linked Offer" />
                      <span className="top-12-linked-offers-tooltip">{Content.getValue('has_linked_offer')}</span>
                    </div>
                  </td>
                </tr>
              ): <></>}
            </tbody>
          </table>
        </div>
      </button>
    </li>
  );
}

export default TopOfferHomepageCard;