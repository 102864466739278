import React, { useState, useEffect, useRef, memo, useContext } from 'react';
import ApiFacade from 'ApiFacade.js';
import Images from 'utils/Images.js';
import Formatting from 'utils/Formatting.js';
import UserData from 'User_Data.js';
import { SpinnerContext } from 'context/Global_Spinner';

import Content from 'utils/Content.js';
import { RedirectWithLang } from '../../utils/RedirectWithLang';
//import JWTUtils from '../../utils/JWT_Utils';
//import LinkCreator from '../../utils/Link_Creator';
const configuration = require('configuration.json');
//const globalConf = require('global_conf.json');

const userDataKeys = configuration.user_data_keys;
const uiSettings = configuration.ui_settings;
//const discordConnection = globalConf.discord_connection;
//const discordCommunityURL = discordConnection.community_url;

const animationDuration = uiSettings.user_stats_animation_duration;

const UserStatsHomepage = () => {
    const { updateIsVisibleSpinner } = useContext(SpinnerContext);
    const [isDetailsVisible, setIsDetailsVisible] = useState(false);
    const [state, setState] = useState({
        error: null,
        isLoaded: false,
        isRestricted: false,
        coinsEarnedToday: 0,
        myTeamMembers: 0,
        refCount: 0,
        refAmount: 0,
        ref2ndGenCount: 0,
        ref2ndGenAmount: 0,
        competitionValidCoins: 0,
        position: 0,
        availableToUse: 0,
        totalCoinsEarned: 0,
        username: '',
    })

    const refCountRef = useRef();
    const refAmountRef = useRef();
    const ref2ndGenCountRef = useRef();
    const ref2ndGenAmountRef = useRef();
    const ref3rdGenCountRef = useRef();
    const ref3rdGenAmountRef = useRef();
    const competitionValidCoinsRef = useRef();
    const availableToUseRef = useRef();
    const totalCoinsEarnedRef = useRef();

    const { refCount,
            refAmount,
            ref2ndGenCount,
            ref2ndGenAmount,
            ref3rdGenCount,
            ref3rdGenAmount,
            competitionValidCoins,
            position,
            availableToUse,
            totalCoinsEarned,
        username } = state;

    //let discordLinkAddress = LinkCreator.createDiscordLink();

    const animateHandler = (obj, prevValue, currValue) => {
        if (prevValue < currValue) 
            animateValue(obj, prevValue || 0, currValue || 0, animationDuration);
    }

    const animateValue = (obj, start, end, duration) => {
        let startTimestamp = null;
        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            const data = Math.floor(progress * (end - start) + start);
            obj.innerHTML = Formatting.formatTreatCoinAmount(data || 0);
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
            else window.cancelAnimationFrame(step)
        };
        window.requestAnimationFrame(step);
    }

    const setLocalStorageVisibleData = ({
        coinsEarnedToday,
        myTeamMembers,
    }) => {
        const ext = JSON.parse(UserData.getItem(userDataKeys.stats));
        const data = {
            ...ext,
            [userDataKeys.stats_values.earned_today]: coinsEarnedToday,
            [userDataKeys.stats_values.team_members]: myTeamMembers,
        }
        UserData.setItem(userDataKeys.stats, JSON.stringify(data));
    }

    const setLocalStorageExpandableData = ({
        refCount,
        refAmount,
        ref2ndGenCount,
        ref2ndGenAmount,
        ref3rdGenCount,
        ref3rdGenAmount,
        competitionValidCoins,
        availableToUse,
        totalCoinsEarned,
        username
    }) => {
        const ext = JSON.parse(UserData.getItem(userDataKeys.stats));
        const data = {
            ...ext,
            [userDataKeys.stats_values.ref_count]: refCount,
            [userDataKeys.stats_values.ref_amount]: refAmount,
            [userDataKeys.stats_values.ref_2nd_gen_count]: ref2ndGenCount,
            [userDataKeys.stats_values.ref_2nd_gen_amount]: ref2ndGenAmount,
            [userDataKeys.stats_values.ref_3rd_gen_count]: ref3rdGenCount || 0,
            [userDataKeys.stats_values.ref_3rd_gen_amount]: ref3rdGenAmount || 0,
            [userDataKeys.stats_values.competition_valid_coins]: competitionValidCoins,
            [userDataKeys.stats_values.available_to_use]: availableToUse,
            [userDataKeys.stats_values.total_coins_earned]: totalCoinsEarned,
            [userDataKeys.stats_values.username]: username
        };
        UserData.setItem(userDataKeys.stats, JSON.stringify(data));
    }

    useEffect(() => {
        if (ApiFacade.isLoggedIn()) {
            updateIsVisibleSpinner(true)
            const req = ApiFacade.userStats();
            req.then(function (res) {
                if (res.ok){
                    let result = res.response;
                    setState({
                        isLoaded: true,
                        ...result.userStats,
                    });
                    setLocalStorageVisibleData(result.userStats);
                } else if (res.unauthorized){
                    setState({
                        isLoaded: true,
                        isRestricted: true
                    });
                }
                else {
                    setState({
                        isLoaded: true,
                        error: res.error
                    });
                }
            },
                (error) => {
                    setState({
                        isLoaded: true,
                        error
                    });
                }
            )
                .catch(error => setState({ isLoaded: true, error }))
                .finally(() => {
                    updateIsVisibleSpinner({ isVisible: false });
                })
        }
    }, []);

    useEffect(() => {
        if (isDetailsVisible) {
            const stats = JSON.parse(UserData.getItem(userDataKeys.stats));
            const prevRefCount = +(stats?.[userDataKeys.stats_values.ref_count] || 0);
            const prevRefAmount = +(stats?.[userDataKeys.stats_values.ref_amount] || 0);
            const prevRef2ndGenCount = +(stats?.[userDataKeys.stats_values.ref_2nd_gen_count] || 0);
            const prevRef2ndGenAmount = +(stats?.[userDataKeys.stats_values.ref_2nd_gen_amount] || 0);
            const prevRef3rdGenCount = +(stats?.[userDataKeys.stats_values.ref_3rd_gen_count] || 0);
            const prevRef3rdGenAmount = +(stats?.[userDataKeys.stats_values.ref_3rd_gen_amount] || 0);
            const prevCompetitionValidCoins = +(stats?.[userDataKeys.stats_values.competition_valid_coins] || 0);
            const prevAvailableToUse = +(stats?.[userDataKeys.stats_values.available_to_use] || 0);
            const prevTotalCoinsEarned = +(stats?.[userDataKeys.stats_values.total_coins_earned] || 0);

            animateHandler(refCountRef.current, prevRefCount, refCount);
            animateHandler(refAmountRef.current, prevRefAmount, refAmount);
            animateHandler(ref2ndGenCountRef.current, prevRef2ndGenCount, ref2ndGenCount);
            animateHandler(ref2ndGenAmountRef.current, prevRef2ndGenAmount, ref2ndGenAmount);
            animateHandler(ref3rdGenCountRef.current, prevRef3rdGenCount, ref3rdGenCount);
            animateHandler(ref3rdGenAmountRef.current, prevRef3rdGenAmount, ref3rdGenAmount);
            animateHandler(competitionValidCoinsRef.current, prevCompetitionValidCoins, competitionValidCoins);
            animateHandler(availableToUseRef.current, prevAvailableToUse, availableToUse);
            animateHandler(totalCoinsEarnedRef.current, prevTotalCoinsEarned, totalCoinsEarned);

            setLocalStorageExpandableData({
                refCount,
                refAmount,
                ref2ndGenCount,
                ref2ndGenAmount,
                ref3rdGenCount,
                ref3rdGenAmount,
                competitionValidCoins,
                availableToUse,
                totalCoinsEarned,
                username
            })
        }
    }, [isDetailsVisible]);

    return (
        <div className='user-stats new'>
            {state.isLoaded ?
            <div className='user-stats-header-container homepage desktop new' ref={null}>
                <div className='user-stats-header desktop new white-i padding-x-5'>
                        <p className="user-stats-header-badge">{Content.getValue('my_earnings')}</p>
                        <div className='user-stats-header-earnings'
                            style={{ transform: `scale(${(state.availableToUse > 9999 || state.coinsEarnedToday > 9999 || state.coinsEarnedLast30Days > 9999) ? 0.9 : 1})` }}
                        >
                                <img
                                    src={Images.imageURL('stats-header-money-icon-new.png')}
                                    alt='Coins earned today'
                                    className='coin'
                                />
                            <div className='user-stats-header-earnings-values'>
                                <span
                                    className='available'
                                >{Formatting.formatTreatCoinAmount(state.availableToUse, true)}</span>
                                <p className='user-stats-header-earnings-info available'>{Content.getValue('avail_to_use')}</p>
                            </div>
                            <div className='user-stats-header-earnings-values'>
                                <span
                                    className='value'
                                >{Formatting.formatTreatCoinAmount(state.coinsEarnedToday, true)}</span>
                                <p className='user-stats-header-earnings-info text-10-i '>{Content.getValue('today')}</p>
                            </div>
                            <div className='user-stats-header-earnings-values'>
                                <span
                                    className='value'
                                >{Formatting.formatTreatCoinAmount(state.coinsEarnedLast30Days, true)}</span>
                                <p className='user-stats-header-earnings-info text-10-i '>{Content.getValue('last_30')}</p>
                            </div>
                        </div>
                    </div>
                    <div className='user-stats-header desktop new white-i padding-x-5 flex justify-center'>
                        <p className="user-stats-header-badge">{Content.getValue('keep_progressing')}</p>
                        {/*
                        <a
                            href={JWTUtils.getConnectedToDiscord() ? discordCommunityURL : discordLinkAddress}
                            target='_blank'
                            className='hidden-button user-stats-header-action'>
                            <img
                                src={Images.imageURL('discord-white-icon.png')}
                                alt='Coins earned today'
                                className='icon discord'
                            />
                            <p className='user-stats-header-text'>{Content.getValue('community')}</p>
                        </a>
                        */}
                        <RedirectWithLang
                            props={{
                                className: "hidden-button user-stats-header-action"
                            }}
                            to={configuration.paths.my_team_referrals}
                        >
                            <img
                                src={Images.imageURL('referrals-page/team-stats-first.png')}
                                className='icon team'
                            />
                            <p className='user-stats-header-text'>{Content.getValue('my_team')}</p>
                        </RedirectWithLang>
                        <RedirectWithLang
                            props={{
                                className: "hidden-button user-stats-header-action"
                            }}
                            to={configuration.paths.my_offers}
                        >
                            <img
                                src={Images.imageURL('my-offer-icon.png')}
                                className='icon offer'
                            />
                            <p className='user-stats-header-text'>{Content.getValue('my_offers')}</p>
                        </RedirectWithLang>
                </div>
            </div>
                : <></>}
            <div className='user-stats-expandable new'>
                <button
                    onClick={() => setIsDetailsVisible(prev => !prev)}
                    className="button-hidden user-stats-expandable new">
                    <img
                        src={isDetailsVisible ? Images.imageURL('stats-arrow-up.png') : Images.imageURL('stats-arrow-down.png')}
                        alt='Expand / Collapse'
                        width='45'
                        height='28' />
                </button>
                                {isDetailsVisible ?
            (
                <div className='stats-component-texts new'>
                    <div className='stats-component-text-item'>
                        <span>{ Content.getValue("first_gen_ref") }</span>
                        <span>
                            #
                            <span ref={refCountRef}>
                                { Formatting.formatTreatCoinAmount(
                                    JSON.parse(UserData.getItem(userDataKeys.stats))?.
                                        [userDataKeys.stats_values.ref_count] || 0) }
                            </span>
                        </span>
                    </div>
                    <div className='stats-component-text-item'>
                        <span>{Content.getValue("total_tc_earned") }</span>
                        <span ref={refAmountRef} className='bold'>
                            { Formatting.formatTreatCoinAmount(
                                JSON.parse(UserData.getItem(userDataKeys.stats))?.
                                    [userDataKeys.stats_values.ref_amount] || 0) }
                        </span>
                    </div>
                    <hr />
                    <div className='stats-component-text-item'>
                        <span>{ Content.getValue("second_gen_ref") }</span>
                        <span>
                            #
                            <span ref={ref2ndGenCountRef}>
                                { Formatting.formatTreatCoinAmount(
                                    JSON.parse(UserData.getItem(userDataKeys.stats))?.
                                        [userDataKeys.stats_values.ref_2nd_gen_count] || 0) }
                            </span>
                        </span>
                    </div>
                    <div className='stats-component-text-item'>
                        <span>{ Content.getValue("total_tc_earned") }</span>
                        <span ref={ref2ndGenAmountRef} className='bold'>
                            { Formatting.formatTreatCoinAmount(
                                JSON.parse(UserData.getItem(userDataKeys.stats))?.
                                    [userDataKeys.stats_values.ref_2nd_gen_amount] || 0) }
                        </span>
                    </div>
                    <hr />
                    <div className='stats-component-text-item'>
                        <span>{ Content.getValue("third_gen_ref") }</span>
                        <span>
                            #
                            <span ref={ref3rdGenCountRef}>
                                { Formatting.formatTreatCoinAmount(
                                    JSON.parse(UserData.getItem(userDataKeys.stats))?.
                                        [userDataKeys.stats_values.ref_3rd_gen_count] || 0) }
                            </span>
                        </span>
                    </div>
                    <div className='stats-component-text-item'>
                        <span>{ Content.getValue("total_tc_earned") }</span>
                        <span ref={ref3rdGenAmountRef} className='bold'>
                            { Formatting.formatTreatCoinAmount(
                                JSON.parse(UserData.getItem(userDataKeys.stats))?.
                                    [userDataKeys.stats_values.ref_3rd_gen_amount] || 0) }
                        </span>
                    </div>
                    <hr />
                    <div className='stats-component-text-item'>
                        <span>{Content.getValue("competition_valid_coins") + ` (#${Formatting.formatTreatCoinAmount(position)})` }</span>
                        <span ref={competitionValidCoinsRef} className='bold'>
                            { Formatting.formatTreatCoinAmount(
                                JSON.parse(UserData.getItem(userDataKeys.stats))?.
                                    [userDataKeys.stats_values.competition_valid_coins] || 0) }
                        </span>
                    </div>
                    <div className='stats-component-text-item'>
                        <span>{ Content.getValue("available_to_use") }</span>
                        <span ref={availableToUseRef} className='bold'>
                            { Formatting.formatTreatCoinAmount(
                                JSON.parse(UserData.getItem(userDataKeys.stats))?.
                                    [userDataKeys.stats_values.available_to_use] || 0) }
                        </span>
                    </div>
                    <div className='stats-component-text-item'>
                        <span>{ Content.getValue("total_tc_earned") }</span>
                        <span ref={totalCoinsEarnedRef} className='bold'>
                            { Formatting.formatTreatCoinAmount(
                                JSON.parse(UserData.getItem(userDataKeys.stats))?.
                                    [userDataKeys.stats_values.total_coins_earned] || 0) }
                        </span>
                    </div>
                </div>
            ) :
            ( null )
            }
            </div>
        </div>
    )
};

export default memo(UserStatsHomepage);