import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Images from 'utils/Images.js';

import Content from 'utils/Content.js';
import Platform from './Platform';

const MySwal = withReactContent(Swal);
const configuration = require('configuration.json');
const platformTypes = configuration.platform_types;
const androidPlatformType = platformTypes.android;
const iOSPlatformType = platformTypes.ios;
class Sharing {

    static async copyToClipboard(text, fallbackTitle, fallbackMessage) {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            // preferred for modern development and suppported for most browsers
            try {
                return await navigator.clipboard.writeText(text);
            }
            catch(ex) {
                Sharing.fallbackToPopup(fallbackTitle, fallbackMessage, text);
            }
        }
        else {
            Sharing.fallbackToPopup(fallbackTitle, fallbackMessage, text);
        } 
        return;
    }

    static async shareLink({ title, text, url, fallbackTitle, fallbackMessage, detailsImageURL }) {
        let imageFile;
        const platformName = Platform.getPlatformName(); 

        const isMobile = platformName === androidPlatformType || platformName === iOSPlatformType;

        if (isMobile && detailsImageURL) {
            const imageFileName = detailsImageURL.split('/').pop();
            imageFile = await Images.createImageFile(detailsImageURL, imageFileName);
        } else {
            imageFile = null;
        }

        const data = {
            title,
            text,
            url
        };

        if (isMobile && imageFile) {
            data.files = [imageFile];
        }

        if (navigator && navigator.share && navigator.canShare(data)) {
            await navigator.share(data);
        } else {
            MySwal.fire({
                title: `<p>${fallbackTitle || title}</p>`,
                html: `<p class='swal2-content'>${fallbackMessage || text}</p><p class='swal2-content'>${url}</p>`,
                background: 'linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%), #C4C4C4',
                confirmButtonText: Content.getValue("got_it_button_text"),
                customClass: {
                    confirmButton: 'standard-confirm-button',
                    cancelButton: 'standard-cancel-button',
                }
            });
        }
    }




    static fallbackToPopup(title, message, text) {
        MySwal.fire({
            title: '<p>' + title + '</p>',
            html: "<p class='swal2-content'>" + message + "</p><p class='swal2-content'>" + text + "</p>",
            iconHtml: '<img alt="TreatCoin robot" class="swal2-robot-icon" src="' + Images.imageURL('robot-icon.png') + '"/>',
            background: 'linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%), #C4C4C4',
            confirmButtonText: Content.getValue("got_it_button_text"),
            customClass: {
                confirmButton: 'standard-confirm-button',
                cancelButton: 'standard-cancel-button',
            }
        });
    }

}

export default Sharing;