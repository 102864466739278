import UserData from 'User_Data.js';
import BasicMenu from './BasicMenu.js';
import Content from 'utils/Content.js';
import { redirectWithLangFunc } from 'utils/RedirectWithLang.js';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;

function LoggedInHeading({ layout }) {
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (to) => {
        setAnchorEl(null);
        if (typeof to === 'string') {
            redirectWithLangFunc(to, navigate);
        }
    };

    return (
        <div className='relative'>
        <BasicMenu
            anchorEl={anchorEl}
            open={open}
            handleClick={handleClick}
            handleClose={handleClose}
            items={[
                { to: configuration.paths.account_settings, text: Content.getValue('menu_title_account_settings') }
            ]}
            >
            <div className={`logged-in-heading logged-in-heading-layout-${layout}`}>
                <p className='logged-in-heading-nickname'>
                    {UserData.getItem(userDataKeys.username)}
                </p>
            </div>
        </BasicMenu>
        </div>
    );
}

export default LoggedInHeading;