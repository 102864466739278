import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ApiFacade from 'ApiFacade.js';
import JWTUtils from 'utils/JWT_Utils.js';
import ContentPageHeader from "components/sections/Content_Page_Header.js";
import Footer from "components/sections/Footer.js";
import { useEffect, useState } from 'react';
import { Mixpanel } from "utils/User_Events_Util.js";
import Platform from 'utils/Platform.js';
import Content from 'utils/Content.js';
import { RedirectWithLang } from 'utils/RedirectWithLang.js';
import Top12OfferPageCard from 'components/top_12_offers/Top_12_Offer_Page_Card.js';
import Formatting from 'utils/Formatting.js';
import UserData from 'User_Data.js';

const configuration = require('configuration.json');
const routerBackValue = configuration.router_back_value;
const mixpanelEvents = configuration.mixpanel_events;
const pages = configuration.pages;
const clickthroughSourceUIPage = configuration.clickthrough_source_ui_page;
const userDataKeys = configuration.user_data_keys;

function Top12Offers() {
  const [items, setItems] = useState(null);
  const [isNew, setIsNew] = useState(false);
  const [sourceUIPage, setSourceUIPage] = useState(clickthroughSourceUIPage.top_12_list); 

  useEffect(() => {
    let platform = Platform.getPlatformName();
    let preferredLanguage = Content.getPreferredLanguage();
    let currentCountry = JWTUtils.getJWTCountry();
    
    const newOffers = UserData.getItem(userDataKeys.new_offers);

    if (newOffers) {
      setIsNew(true);
      setSourceUIPage(clickthroughSourceUIPage.latest_drops_list);
    }

    const req = ApiFacade.top12Offers(preferredLanguage, currentCountry, platform);
    req.then( (res) => {
      let filteredOffers = res.response.offers;

      UserData.removeItem(userDataKeys.new_offers);
      filteredOffers = filteredOffers.filter(offer => newOffers ? ((offer.position || 1000000) >= 1000000 && offer.ActivationDate !== null && offer.ActivationDate !== undefined) : offer.position < 1000000);
      setItems(filteredOffers);
    });
    const cards = document.querySelectorAll('.top-12-offer-page-card');

    // Find the maximum height among all cards
    let maxHeight = 0;
    cards.forEach(card => {
      const cardHeight = card.getBoundingClientRect().height;
      maxHeight = Math.max(maxHeight, cardHeight);
    });
  
    cards.forEach(card => {
      card.style.minHeight = `${maxHeight}px`;
    });
  }, []);

  useEffect(() => {
    Mixpanel.trackByArgs(mixpanelEvents.page_view, { page_name: pages.top_12_offers });
  }, []);

  return (
    <>
    <div id="main">
        <div id="content">
      <ContentPageHeader />
      <section className="content-page-section">
      <div className="top-12-offers-page-container">
              <div className="top-offers-homepage-header  top-12">
                <div className='top-offers-homepage-header-left max-w-half'>
            <h2 className="top-offers-homepage-name">
                    {isNew ?
                      Content.getValue('latest_drops')
                      :Formatting.format(Content.getValue("earn_from_top_12"), ["(offers)"], [items?.length || ''])}
            </h2>
          </div>
                <RedirectWithLang
                  to={routerBackValue}
                  className="top-offers-homepage-btn hidden-button "
                  isButton={true}
                >
                  <>
                    <KeyboardArrowLeftIcon fontSize='large' />
                    {Content.getValue('back')}</>
                </RedirectWithLang>
        </div>
        <ul className="top-offers-page-list">
          {items && items.map((i, idx) => <Top12OfferPageCard  offer={i} index={idx + 1} key={`top12offerPagecardkey-${idx}`} sourceUIPage={sourceUIPage}/>)}
        </ul>
      </div>
      </section>
      </div>
      <Footer />

      </div>
    </>
  );
}

export default Top12Offers;