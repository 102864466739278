import UserData from 'User_Data.js';
import jwt from 'jwt-decode';

const configuration = require('configuration.json');

const userDataKeys = configuration.user_data_keys;

class JWTUtils {

    static getJWTCountry() {
        let userJWTToken = UserData.getItem(userDataKeys.jwt_token);
        let guestJWTToken =  UserData.getItem(userDataKeys.guest_token);
        let token = userJWTToken ? userJWTToken: guestJWTToken;
        return jwt(token).country;
    }
    
    static getConnectedToDiscord() {
        let userJWTToken = UserData.getItem(userDataKeys.jwt_token);
        return jwt(userJWTToken).connectedToDiscord;
    }

    static guestTokenHasExpired() {
        let guestJWTToken =  UserData.getItem(userDataKeys.guest_token);
        let decodedToken = jwt(guestJWTToken);
        let currentTime = new Date().getTime() / 1000;
        return decodedToken.exp < currentTime;
    }
}

export default JWTUtils;