import UserData from 'User_Data.js';
import { createContext, useState } from 'react';
import OfferEvents from "utils/Offer_Events.js";
import Platform from 'utils/Platform.js';

const configuration = require("configuration.json");

const eventTypes = configuration.event_types;
const userDataKeys = configuration.user_data_keys;

const OfferPopupContext = createContext();

const OfferPopupContextProvider = ({ children }) => {
    const [isOpenedOfferPopup, setIsOpenedOfferPopup] = useState(false);
    const [offer, setOffer] = useState(null);
    const [sourceUIPage, setSourceUIPage] = useState(null);

    const handleOfferIsOpened = async ({ isOpened = true, offer = null, sourceUIPage = null }) => {
        if (isOpened === true) {
            setOffer(offer);
            await OfferEvents.sendEvent({
                eventType: eventTypes.click,
                offerID: offer.ID,
                appName: offer.appName,
                category: offer.category,
                offerType: offer.offerType,
                UserPayout: Number(UserData.getItem(userDataKeys.offer_coins_amount)),
                isFree: offer.isFree,
                platformName: Platform.getPlatformName(),
                offerWallVisitID: UserData.getItem(userDataKeys.offer_wall_visit_id),
                uniqueID: offer.uniqueID,
                url: offer.url,
                providerID: offer.providerID,
                isSystem: offer.isSystem,
            })
        }
        else {
            setOffer(null);
        }
        setIsOpenedOfferPopup(isOpened);
        setSourceUIPage(sourceUIPage);
    };
    
    return (
        <OfferPopupContext.Provider value={{ isOpenedOfferPopup, handleOfferIsOpened, offer, setOffer, sourceUIPage }}>
            {children}
        </OfferPopupContext.Provider>
    );
};

export { OfferPopupContext, OfferPopupContextProvider };
