import React from 'react';
import Images from 'utils/Images.js';
import Formatting from 'utils/Formatting.js';
import OfferDifficultyIndicator from 'components/offers/Offer_Difficulty_Indicator.js';
import IncompleteOffer from 'components/offers/Incomplete_Offer.js';
import OfferThemeIndicator from 'components/offers/Offer_Theme_Indicator.js'

import Content from 'utils/Content.js';
import { OfferPopupContext } from 'context/Offer_Popup_Context.js';
import BlurProvider from 'components/offer_providers/Blur_Provider.js';
import Offers from 'utils/Offers.js';
const configuration = require('configuration.json');
const clickthroughSourceUIPage = configuration.clickthrough_source_ui_page;

const incompleteOfferModes = configuration.incomplete_offer_modes;

class CompletedOffer extends React.Component {

    constructor(props) {
        super(props);
        this.offer = props.offer;
        const brandsExpanded = props.brandsExpanded || false;
        this.state = {
            brandsExpanded
        };
    }

    static contextType = OfferPopupContext;

    toggleExpanded() {
        const { brandsExpanded } = this.state;
        this.setState({
            brandsExpanded: !brandsExpanded
        });
    }

    generateCoinsSumLabel(earlyUnlimitedCompletions, earlyOneTimeCompletions, unlimitedCompletions, oneTimeCompletions, dailyCompletions, isDaily) {
        let earlyCompletions = Math.max(earlyUnlimitedCompletions, earlyOneTimeCompletions);
        let regularCompletions = Math.max(unlimitedCompletions, oneTimeCompletions);
        if (isDaily) {
            return <span className="finished-label">{Formatting.format(Content.getValue("finished_daily_label"), ["(dailyCompletions)", "(completions)"], [dailyCompletions, earlyCompletions + regularCompletions])}</span>
        }
        else {
            return <span className="finished-label">{Formatting.format(Content.getValue("finished_unlimited_label"), ["(completions)"], [earlyCompletions + regularCompletions])}</span>
        }
    }

    generateIndicator(isUnlimited, isDaily, isCompetitionOnly, dailyCap, isRightAligned) {
        if (isUnlimited) {
            return (
                <>
                    {
                        isCompetitionOnly ?
                        <img className={`unlimited-indicator-completed ${isRightAligned ? "right" : ""}`} alt="Completed infinite times" src={Images.imageURL('award-indicator-gray.png')} width="22" height="auto" /> :
                        <img className={`unlimited-indicator-completed ${isRightAligned ? "right" : ""}`} alt="Completed infinite times" src={Images.imageURL('unlimited-indicator.png')} width="22" height="auto" />
                    }
                    <span className="unlimited-indicator-popup-image-container">
                        <div className="unlimited-indicator-popup-text">{Content.getValue("complete_offers_unlimited_times")}</div>
                        <img className="unlimited-indicator-popup-image" src={Images.imageURL('unlimited-indicator-popup.png')} alt="Unlimited indicator popup" width="79" height="28" />
                    </span>
                </>
            );
        }
        else if (isDaily) {
            return (
                <>
                    <div className={`daily-indicator-container-completed ${isRightAligned ? "right" : ""}`}>
                        <span className="daily-times-label">{dailyCap}</span>
                        {
                            isCompetitionOnly ?
                            <img className="daily-indicator-completed" alt="Complete daily" src={Images.imageURL('award-indicator-gray.png')} width="24" height="auto" /> :
                            <img className="daily-indicator-completed" alt="Complete daily" src={Images.imageURL('daily-indicator.png')} width="24" height="auto" />
                        }
                        <span className="daily-indicator-popup-image-container">
                            <div className="daily-indicator-popup-text">{Formatting.format(Content.getValue("complete_multiple_times_daily"), ["(dailyCap)"], [dailyCap])}</div>
                            <img className="daily-indicator-popup-image" src={Images.imageURL('daily-indicator-popup.png')} alt="Unlimited indicator popup" width="79" height="28" />
                        </span>
                    </div>
                </>
            );
        }
        else return (null);

    }

    render() {
        const { handleOfferIsOpened } = this.context;
        const { brandsExpanded } = this.state;
        const { isMultiEvent } = this.props;
        let offer = this.offer;
        let moreOffers = offer.moreOffers;
        const data = Offers.getBlurredOfferData(moreOffers);
        const progressWidth = isMultiEvent ? 
            offer.completedSteps * 100 / offer.totalSteps :
            100;
        const sourceUIPage = clickthroughSourceUIPage.in_progress;
        return <div
            className={(!brandsExpanded) ? "completed-offer-list-container" : "completed-offer-list-container brands-expanded"}>
            <div
                onClick={() => {
                    handleOfferIsOpened({ offer, isOpened: true, sourceUIPage });
                }}
                className={(!brandsExpanded) ? "list-completed-offer-root" : "list-completed-offer-root no-border"}>
            <div className='list-completed-offer-container'>
                <div className="list-offer-container-image">
                        <OfferDifficultyIndicator difficultyLevel={offer.difficultyLevel} difficultyLevelID={offer.difficultyLevelID} imageType="white" />
                    <img className="list-offer-image" src={offer.imageURL} alt={offer.description} width="50" height="auto" />
                        <OfferThemeIndicator offer={offer} />
                </div>
                <div className="list-offer-container-titles desktop">
                    <div>
                        <div className="list-offer-app-name">{offer.appName}</div>
                        <div className="list-offer-description">{offer.MainTitle}</div>
                            <div className="list-offer-hash-tags completed-offer"></div>
                    </div>
                </div>
                <div className={`list-offer-container-unlimited ${isMultiEvent ? "flex items-start" : ""}`}>
                    {
                        (moreOffers.length > 0 && !isMultiEvent) ? (
                                <button
                                    className='more-offers-button'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        return this.setState({ brandsExpanded: !(this.state.brandsExpanded) });
                                    }}
                                >
                                <img 
                                    className={(!brandsExpanded) ? "more-offers-icon plus" : "more-offers-icon minus"} 
                                    alt="More offers" 
                                    src={(!brandsExpanded) ? Images.imageURL('expand-more-offers.png') : Images.imageURL('close-more-offers.png')} 

                                    width="14" 
                                    height="auto" 
                                />
                                {
                                    (!brandsExpanded) ? (
                                        <span className="expand-popup-image-container">
                                            <div className="more-from-brand-popup-text">{Content.getValue("more_from_this_brand")}</div>
                                            <img className="more-from-brand-popup-image" src={Images.imageURL('more-from-brand-popup.png')} alt="More from fav brands popup" width="79" height="28" />
                                        </span>) : (null)
                                }
                                </button>
                        ) :
                            isMultiEvent ?
                            (
                                <span className="list-offer-app-name multi-event">
                                    <a 
                                        href={offer.nextOfferStepUrl}
                                        className="black-text-opacity"
                                    >
                                        {
                                            Formatting.format(
                                                Content.getValue("continue_to_step_number"), 
                                                ["(number)"],
                                                [offer.completedSteps + 1]
                                            )
                                        }
                                    </a>
                                </span>
                            ) :
                                (null)
                    }
                    {
                        this.generateIndicator(offer.isUnlimited, offer.isDaily, offer.isCompetitionOnly, offer.dailyCap, isMultiEvent ? true : false)
                    }
                </div>
            </div>
            <div className="finished-indicator-wrapper">
                <div className="finished-indicator-text-left">
                    {
                        (isMultiEvent) ?
                            (<span className="finished-label">{
                                Formatting.format(
                                    Content.getValue("finished_steps_label"), 
                                    ["(currentStep)", "(totalSteps)"],
                                    [offer.completedSteps, offer.totalSteps]
                                )
                            }</span>) :
                            (offer.isUnlimited || offer.isDaily) ?
                                (
                                    this.generateCoinsSumLabel(offer.earlyUnlimitedCompletions, offer.earlyOneTimeCompletions, offer.unlimitedCompletions, offer.oneTimeCompletions, offer.dailyCompletions, offer.isDaily)
                                ) :
                                (<span className="finished-label">{Content.getValue("finished_label")}</span>)
                    }
                </div>
                    <div className="finished-indicator-text-right">
                        <span 
                            className="finished-total-label" 
                            dangerouslySetInnerHTML={{ 
                                __html: Formatting.format(
                                    Content.getValue("total_completion_coins_label"), 
                                    ["(totalCoins)"], 
                                    [Formatting.formatTreatCoinAmount(isMultiEvent ? offer.coinsEarned : offer.totalCoinsEarned)]) 
                            }} />
                    </div>
            </div>
            <div className="finished-indicator-bar" style={{ width: progressWidth + "%" }}></div>
            </div>
            {
                    <div className={`completed-offers-more ${brandsExpanded ? 'expanded' : ''}`}>
                    <div className="more-from-brand-label">{Content.getValue("more_from_this_brand")}</div>
                        {
                            data.map(item => (item.length===1) ?
                                (
                                    <BlurProvider key={`_offer-container-${item.offers[0].ID}_${Date.now()}`} fullWidth count={1}>
                                        <IncompleteOffer offer={item.offers[0]} selectedHashTags={[]} numIncompleteOffers={offer.moreOffers.length} mode={incompleteOfferModes.more_offers_list} sourceUIPage={sourceUIPage}/>
                                    </BlurProvider>
                                ) :
                                    (item.length>1) ?
                                    (
                                        <BlurProvider key={`_offer-completed-container-${item.offers[0].ID}_${Date.now()}`} fullWidth count={item.length}>
                                            {item.offers.map(additionalOffer => (
                                                <IncompleteOffer key={`_offer-completed-completed-${additionalOffer.ID}_${Date.now()}`} offer={additionalOffer} selectedHashTags={[]} numIncompleteOffers={offer.moreOffers.length} mode={incompleteOfferModes.more_offers_list} sourceUIPage={sourceUIPage} />
                                            ))}
                                        </BlurProvider>
                                    ) :
                                        (
                                            <IncompleteOffer key={`_offer-completed-${item.offers[0].ID}_${Date.now()}`} offer={item.offers[0]} selectedHashTags={[]} numIncompleteOffers={offer.moreOffers.length} mode={incompleteOfferModes.more_offers_list} sourceUIPage={sourceUIPage} />
                                        )
                            )
                        }
                    </div> 
            }
        </div>;
    }
}

export default CompletedOffer;