import React, { useContext } from 'react';
import Images from 'utils/Images';
import Formatting from 'utils/Formatting';
import Joining from 'utils/Joining';
import { OfferPopupContext } from 'context/Offer_Popup_Context';
import { useNavigate } from 'react-router-dom';
import ApiFacade from 'ApiFacade.js';
import Content from '../../../utils/Content';

const configuration = require('configuration.json');
const clickthroughSourceUIPage = configuration.clickthrough_source_ui_page;

function AdditionalOfferCard({ categoryID, offer }) {
  const navigate = useNavigate();
  const { handleOfferIsOpened } = useContext(OfferPopupContext)
  const { imageURL, appName, description, coins, treatCoinValue, ID, hasLinkedOffers, CoinsUpTo } = offer;

  const sourceUIPage = clickthroughSourceUIPage.categories;

  return (
    <li
      onClick={() => {
        if (ApiFacade.isLoggedIn()) {
          handleOfferIsOpened({isOpened: true, offer, sourceUIPage})
        } else {
          Joining.showPopup(navigate);
        }
      }}
      key={ID} className={`additional-offer-card additional-offer-card-${categoryID}`}>
      <div>
        <div className='additional-offer-card-img'
          style={{ backgroundImage: `url(${imageURL})`, backgroundSize: 'contain' }}
        />
        <h4 className="additional-offer-card-name">{appName}</h4>
        <p className="additional-offer-card-desc">{Formatting.shortenText(description, 200)}</p>
      </div>
      <div className='margin-bottom-5'>
        <div className="additional-offer-card-border"></div>
        
        <table className="offers-categories-table">
          <tbody>
            {CoinsUpTo ? (
              <tr>
                <td colSpan="3" className="top-offer-homepage-up-to">
                  {Content.getValue('top_offers_up_to')}
                </td>
              </tr>
            ) : <></>}

            <tr>
              <td className="top-offer-coin">
                <img
                  width={20}
                  height={20}
                  src={Images.imageURL('offers/stats-header-money-icon-new.png')}
                  alt="TreatCoin"
                  className="top-offer-homepage-treat-coin"
                />
              </td>

              <td className="offer-details">
                <p className="top-offer-homepage-tc-value">
                  {CoinsUpTo ? CoinsUpTo.toLocaleString() : coins.toLocaleString()}
                </p>
              </td>
              <td className='top-offer-homepage-usd-value text-12-i'>= </td>
              <td>
                <p className="top-offer-homepage-usd-value text-12-i">
                  {Formatting.currencySymbol()}
                  {((CoinsUpTo ? CoinsUpTo : coins) * treatCoinValue).toFixed(2)}
                </p>
              </td>
            </tr>
            <tr className='offers-categories-homepage-currencies'>
              <td></td>
              <td className='top-offer-homepage-card-usd-desc'>
                <p>{Content.getValue('treat_coins')}</p>
              </td>
              <td></td>
              <td className='top-offer-homepage-card-tc-desc'>
                <p>
                  {Formatting.currencyLabel()}
                </p>
              </td>
            </tr>

            {hasLinkedOffers ? (
              <tr>
                <td colSpan="3" className="offer-linked">
                  <div className="offers-categories-homepage-linked-offer">
                    <img src={Images.imageURL('offers/linked-offer.png')} alt="Linked Offer" />
                    <span className="offers-categories-linked-offers-tooltip">{Content.getValue('has_linked_offer')}</span>
                  </div>
                </td>
              </tr>
            ) : <></>}
          </tbody>
        </table>
        
      </div>
    </li>
  )
}

export default AdditionalOfferCard