import React from 'react';
import Images from 'utils/Images.js';
import OfferDifficultyIndicator from 'components/offers/Offer_Difficulty_Indicator.js';
import Offers from 'utils/Offers.js';
import Formatting from 'utils/Formatting.js';
import OfferThemeIndicator from 'components/offers/Offer_Theme_Indicator.js'

import Content from 'utils/Content.js';
import { OfferPopupContext } from 'context/Offer_Popup_Context.js';
const configuration = require('configuration.json');

const incompleteOfferModes = configuration.incomplete_offer_modes;

class IncompleteOffer extends React.Component {

    constructor(props) {
        super(props);
        this.offer = props.offer;
        this.selectedHashTags = props.selectedHashTags;
        this.numIncompleteOffers = props.numIncompleteOffers;
        this.mode = props.mode;
        this.availabilityStatus = props.availabilityStatus;
        this.newCoins = props.newCoins;
        this.sourceUIPage = props.sourceUIPage;
    }

    static contextType = OfferPopupContext;

    render() {
        const { handleOfferIsOpened } = this.context;

        const sourceUIPage =  this.sourceUIPage;
        let offer = this.offer;
        let multiSteps = offer && JSON.parse(offer.multiSteps || null);
        const multiStepsSum = Offers.getMultiStepsSum(multiSteps);

        let offerContainerClassName;
        if (this.mode === incompleteOfferModes.more_offers_list) {
            offerContainerClassName = 'list-offer-container extra-offer';
        }
        else if (this.mode === incompleteOfferModes.favourite_offers_list) {
            offerContainerClassName = 'list-offer-container favourite-offer desktop';
        }
        else if (this.mode === incompleteOfferModes.recommended_offer) {
            offerContainerClassName = 'list-offer-container recommended-offer desktop';
        }
        else if (this.mode === incompleteOfferModes.unavailable_offer) {
            offerContainerClassName = 'list-offer-container unavailable-offer';
        }
        else {
            if (this.numIncompleteOffers > 1) offerContainerClassName = 'list-offer-container';
            else offerContainerClassName = 'single-list-offer-container desktop';
        }
        let hashTagsContainerClassName;
        if (this.mode === incompleteOfferModes.favourite_offers_list) {
            hashTagsContainerClassName = 'list-offer-hash-tags favourite-offer';
        }
        else {
            hashTagsContainerClassName = 'list-offer-hash-tags';
        }
        return <div className={offerContainerClassName} 
            onClick={() => { 
                    if (this.mode !== incompleteOfferModes.unavailable_offer) {
                        handleOfferIsOpened({ offer, sourceUIPage }); 
                    }
                }}>
            {(this.mode === incompleteOfferModes.unavailable_offer) ? (
                <div className="recommended-offer-not-available"><div>{this.availabilityStatus}</div></div>
            ) : (null)
            }
            <div className="list-offer-container-image">
                <OfferDifficultyIndicator difficultyLevel={offer.difficultyLevel} difficultyLevelID={offer.difficultyLevelID} imageType="white" />
                <img className="list-offer-image" src={offer.imageURL} alt={offer.description} width="36" height="auto" />
                <OfferThemeIndicator offer={offer} />
            </div>
            <div className="list-offer-container-titles desktop">
                <div>
                    {(this.mode !== incompleteOfferModes.recommended_offer && this.mode !== incompleteOfferModes.unavailable_offer) ? (<div className="list-offer-app-name">{offer.appName}</div>) : (null)}
                    <div className="list-offer-description">{offer.MainTitle}</div>
                    <div className={hashTagsContainerClassName} ></div>
                </div>
            </div>
            <div className="list-offer-container-button desktop">
                <button className="list-offer-button desktop">
                    {
                        multiStepsSum && multiStepsSum.current === 0 ?
                        (
                            <p className="margin-bottom-5">{Content.getValue("up_to")}</p>
                        ) :
                        null
                    }
                    <p className="coins-amount">
                        {
                            multiStepsSum ?
                                multiStepsSum.current === 0 ?
                                Formatting.formatTreatCoinAmount(multiStepsSum.total) :
                                Formatting.formatTreatCoinAmount(multiStepsSum.current) + "/" + Formatting.formatTreatCoinAmount(multiStepsSum.total) :
                                    offer.isPercentage ? 
                                    (offer.coins + '%') : 
                                        !offer.allowsUsableAndCompetition ? 
                                        Formatting.formatTreatCoinAmount(offer.coinsCompetition) : 
                                        Formatting.formatTreatCoinAmount(offer.coins)
                        }
                    </p>
                    <p>{Content.getValue("treat_coins")}</p>
                    <div className="flex justify-center">
                        {
                            (offer.isUnlimited) ? (
                                    <>
                                        <img className="unlimited-indicator-list mobile" alt="Unlimited offer" width="18" height="auto" src={Images.imageURL('unlimited-indicator-white.png')} />
                                        <img className="unlimited-indicator-list desktop" alt="Unlimited offer" width="18" height="auto" src={Images.imageURL('unlimited-indicator-purple.png')} />
                                    </>
                                ) :
                                (null)
                        }
                        {
                            (offer.isDaily) ? (
                                <div className="daily-indicator-list-container">
                                    <span className="daily-indicator-list-text incomplete-list">{offer.dailyCap}</span>
                                    <>
                                        <img className="daily-indicator-list mobile" alt="Daily offer" width="18" height="auto" src={Images.imageURL('daily-indicator-white.png')} />
                                        <img className="daily-indicator-list desktop" alt="Daily offer" width="18" height="auto" src={Images.imageURL('daily-indicator-purple.png')} />
                                    </>
                                </div>
                            ) :
                                (null)
                        }
                        {
                            (!offer.allowsUsableAndCompetition) ? 
                            (
                                <>
                                    <img

                                        width="18"
                                        height="18"
                                        className="award-indicator-list mobile"
                                        src={Images.imageURL("award-indicator-white.png")}
                                    />
                                    <img

                                        width="18"
                                        height="18"
                                        className="award-indicator-list desktop"
                                        src={Images.imageURL("award-indicator-purple.png")}
                                    />
                                </>
                                
                            ) :
                            (null)
                        }
                    </div>
                </button>
            </div>
        </div>;
    }
}

export default IncompleteOffer;