import ApiFacade from 'ApiFacade.js';
import OffersClickedCheckbox from 'components/checkboxes/Offers_Clicked_Checkbox.js';
import ContentPageHeader from 'components/sections/Content_Page_Header.js';
import Top12OfferPageCard from 'components/top_12_offers/Top_12_Offer_Page_Card.js';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import Content from 'utils/Content.js';
import Images from 'utils/Images.js';
import { redirectWithLangFunc } from 'utils/RedirectWithLang.js';
import { SpinnerContext } from 'context/Global_Spinner.js';
import Footer from 'components/sections/Footer';


const configuration = require('configuration.json');
const offerClickedReportStatuses = configuration.offers_clicked_report_statuses;
const clickthroughSourceUIPage = configuration.clickthrough_source_ui_page;

const PAGE_SIZE = configuration.business_logic.offers_clicked_page_size;

const ReportSortByItem = ({ checked, text, setIsChecked }) => {
    return (
        <li className="offers-clicked-sortby-item checkbox-wrapper">
            <OffersClickedCheckbox text={text} checked={checked} setIsChecked={setIsChecked} />
        </li>
    );
}

function OffersClicked() {
    const navigate = useNavigate();
    const [sortByOpened, setSortByOpened] = useState(false);
    const [page, setPage] = useState(1);
    const order = -1;
    const [chosenReportStatus, setChosenReportStatus] = useState(null);
    const [offers, setOffers] = useState([]);
    const { updateIsVisibleSpinner } = useContext(SpinnerContext);

    const sourceUIPage = clickthroughSourceUIPage.offers_clicked;

    const getClickedOffers = async () => {

        try {
            updateIsVisibleSpinner({ isVisible: true });

            const res = await ApiFacade.receiveClickedOffers({ page, order, status: chosenReportStatus, amount: PAGE_SIZE });

            if (res.ok) {
                const response = res.response;
                if (response.offers.length === 0) {
                    setPage(null);
                }
                setOffers(prev => {
                    const result = [...response.offers].filter(
                        (obj, index, self) =>
                            index ===
                            self.findIndex((t) => t.ID === obj.ID)
                    );

                    if (page !== 1) {
                        result.unshift(...prev);
                    }

                    return result;
                });
            }

            setSortByOpened(false);
        }
        finally {
            updateIsVisibleSpinner({ isVisible: false});
        }
    }
    
    useEffect(() => {
        if (page !== null) {
            getClickedOffers();
        }
    }, [page, order, chosenReportStatus])
    

  return (
      <>
          <div id="main">
              <div id="content" className='offers-clicked'>

                  <ContentPageHeader />

                  <div className="offers-clicked-top">
                      <button
                          onClick={() => { redirectWithLangFunc(configuration.paths.home, navigate); }}
                          className="hidden-button">
                          <img
                              width={24} height={24}
                              className='offers-clicked-back-arrow'
                              src={Images.imageURL('arrow_back.png')} alt="Arrow" />
                      </button>
                      <h2 className="offers-clicked-heading">{Content.getValue('offers_clicked')}</h2>
                      <button className={`hidden-button offers-clicked-sort-btn ${sortByOpened && 'purple-border'}`}
                          onClick={() => {
                              setSortByOpened(prev => !prev);
                          }}
                      > 
                          <span className={`offers-clicked-sort-btn-text ${sortByOpened && 'purple-text'}`}>{Content.getValue('filtered_by')}</span>
                          <img
                              width={16} height={18}
                              className='offers-clicked-sort-icon'
                              src={Images.imageURL(sortByOpened?'sort_by_purple.png':'sort_by_white.png')} alt="Arrow" />
                      </button>
                      <ul 
                      className={`offers-clicked-sort-popup ${sortByOpened ? 'opened' : 'hidden'}`}>
                          {Object.values(offerClickedReportStatuses).map(status => <ReportSortByItem
                              key={'ReportSortByItem' + status}
                              checked={chosenReportStatus === status}
                              text={Content.getValue(`report_status_text_${status}`)}
                              setIsChecked={() => {
                                  setOffers([]);
                                  setPage(1)
                                  setChosenReportStatus(prev => status === prev ? null : status);
                              }}
                          />)}
                      </ul>
                  </div>

                  <ul className="offers-clicked-list top-offers-page-list">
                      {offers && offers?.map((offer, idx) => <Top12OfferPageCard showReportStatus={true} offer={{ ...offer, isClicked: true }} key={`top12offerPagecardkey-${idx}`} showGold={false} sourceUIPage={sourceUIPage} />)}
                  </ul>
                  
                  <Waypoint
                      onEnter={() => {
                          if (offers.length === PAGE_SIZE * page) {
                            setPage(prev => {
                                if (page !== null) {
                                    return prev + 1;
                                }
                            });
                        }
                      }}
                  />
              </div>
              <Footer />
          </div>
      </>
  )
}

export default OffersClicked