import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Component } from 'react';
import Backend from 'Backend.js';
import UserData from 'User_Data.js';
import Images from 'utils/Images.js';
import Content from 'utils/Content.js';
import Platform from 'utils/Platform.js';
import { redirectWithLangFunc } from 'utils/RedirectWithLang.js';
import Redirects from 'utils/Redirects.js';
import ApiFacade from 'ApiFacade.js';
import JWTUtils from 'utils/JWT_Utils.js';

const MySwal = withReactContent(Swal);

const configuration = require('configuration.json');

const userDataKeys = configuration.user_data_keys;
const cookies = configuration.cookies;
const loginTypes = configuration.login_types;


class LoginDiscord extends Component {

    static redirectToHomepage(navigate) {

        redirectWithLangFunc(configuration.paths.home, navigate);
    }

    static redirectToWelcomePage() {
        
        const welcomeUrl = configuration.paths.welcome;
        redirectWithLangFunc(welcomeUrl);
    }

    static isSetToRedirectToDiscordCommunityAfterAuth() {
        return (UserData.getItem(userDataKeys.redirect_to_discord_community_after_auth) !== null);
    }

    static setToRedirectToDiscordCommunity() {
        UserData.removeItem(userDataKeys.redirect_to_discord_community_after_auth);
        UserData.setItem(userDataKeys.redirect_to_discord_community, true);
    }

    static handleDiscord(discordRedirectRequestCode, navigate, updateIsVisibleSpinner) {
        updateIsVisibleSpinner({ isVisible: true, contentKey: 'logging_in_with_discord' });

        if (!ApiFacade.isLoggedIn()) {
            const startRegistration = function() {
                const gclient_id = UserData.getItem(userDataKeys.gclient_id);
                const fbclid = UserData.getCookie(cookies.fbc) || UserData.getItem(userDataKeys.fbclid);
                const fbp = UserData.getCookie(cookies.fbp);
                LoginDiscord.handleDiscordLogin(discordRedirectRequestCode, gclient_id, fbclid, fbp, navigate, updateIsVisibleSpinner);
            };
            Backend.startAfterAnalyticsInitialized(startRegistration);
        }
        else {
            if (JWTUtils.getConnectedToDiscord() === true) {
                LoginDiscord.redirectToHomepage(navigate);
            }
            else {
                LoginDiscord.handleDiscordMerge(discordRedirectRequestCode, updateIsVisibleSpinner);
            } 
        }
    }

    static handleDiscordLogin(discordCode, gclient_id, fbclid, fbp, navigate, updateIsVisibleSpinner) {

        let robotIconURL = Images.imageURL('robot-icon.png');

        let ref = UserData.getItem(userDataKeys.referrer_code);
        const platform = Platform.getPlatformName();

        const postBody = {
            discordCode: discordCode,
            ref: ref,
            platformName: platform,
            gclient_id: gclient_id, 
            fbclid: fbclid,
            fbp: fbp
        }

        fetch(Backend.backendURLs.discordLoginURL, {
            credentials: "include",
            method: Backend.backendMethods.post,
            headers: Backend.generateHeader(),
            body: JSON.stringify(postBody)
        }).then(async function (res) {
            updateIsVisibleSpinner({ isVisible: false });
            if (res.status === Backend.backendResponseCodes.internalServerErrorCode || res.status === Backend.backendResponseCodes.serviceUnavailableCode) {
                MySwal.fire({
                    title: '<p>' + Content.getValue("discord_login_failed_title") + '</p>',
                    html: '<p class="swal2-content">' + Backend.loadBackendMessages().errorMessage + '</p>',
                    iconHtml: '<img alt="TreatCoin robot" class="swal2-robot-icon" src="' + robotIconURL + '"/>',
                    background: 'linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%), #C4C4C4',
                    showCancelButton: false,
                    confirmButtonText: Content.getValue("close_button_text"),
                    customClass: {
                        confirmButton: 'standard-confirm-button'
                    }
                });
            }
            else if (res.status === Backend.backendResponseCodes.badRequestCode) {
                const result = await res.json();
                if (result.accountSuspended === true) {
                    redirectWithLangFunc(configuration.paths.account_suspended, navigate);
                }
                else {
                    MySwal.fire({
                        title: '<p>' + Content.getValue("discord_login_failed_title") + '</p>',
                        html: '<p class="swal2-content">' + Content.getValue(result.message) + '</p>',
                        iconHtml: '<img alt="TreatCoin robot" class="swal2-robot-icon" src="' + robotIconURL + '"/>',
                        background: 'linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%), #C4C4C4',
                        showCancelButton: false,
                        confirmButtonText: Content.getValue("close_button_text"),
                        customClass: {
                            confirmButton: 'standard-confirm-button'
                        }
                    });
                }
            }
            else {
                const result = await res.json();
                const isSetToRedirectToDiscordCommunityAfterAuth = LoginDiscord.isSetToRedirectToDiscordCommunityAfterAuth();
                await Backend.setUserSessionData(result, loginTypes.discord);
                if (Redirects.recommendedOrEmailOfferSet()) {
                    redirectWithLangFunc(configuration.paths.playground, navigate);
                }
                else {
                    if (isSetToRedirectToDiscordCommunityAfterAuth) {
                       LoginDiscord.setToRedirectToDiscordCommunity();
                    }
                    if (result.existingAccount) {
                        LoginDiscord.redirectToHomepage(navigate);
                    }
                    else {
                        LoginDiscord.redirectToWelcomePage();
                    }
                }
            }

        });
    }

    static handleDiscordMerge(discordCode, updateIsVisibleSpinner) {

        let robotIconURL = Images.imageURL('robot-icon.png');

        const postBody = {
            discordCode: discordCode
        }

        fetch(Backend.backendURLs.mergeDiscordURL, {
            method: Backend.backendMethods.post,
            headers: Backend.generateHeader(),
            body: JSON.stringify(postBody)
        }).then(async function (res) {
            updateIsVisibleSpinner({ isVisible: false });
            if (res.status === Backend.backendResponseCodes.internalServerErrorCode || res.status === Backend.backendResponseCodes.serviceUnavailableCode) {
                MySwal.fire({
                    title: '<p>' + Content.getValue("discord_login_failed_title") + '</p>',
                    html: '<p class="swal2-content">' + Backend.loadBackendMessages().errorMessage + '</p>',
                    iconHtml: '<img alt="TreatCoin robot" class="swal2-robot-icon" src="' + robotIconURL + '"/>',
                    background: 'linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%), #C4C4C4',
                    showCancelButton: false,
                    confirmButtonText: Content.getValue("close_button_text"),
                    customClass: {
                        confirmButton: 'standard-confirm-button'
                    }
                });
            }
            else if (res.status === Backend.backendResponseCodes.badRequestCode) {
                const result = await res.json();
                MySwal.fire({
                    title: '<p>' + Content.getValue("discord_login_failed_title") + '</p>',
                    html: '<p class="swal2-content">' + Content.getValue(result.message) + '</p>',
                    iconHtml: '<img alt="TreatCoin robot" class="swal2-robot-icon" src="' + robotIconURL + '"/>',
                    background: 'linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%), #C4C4C4',
                    showCancelButton: false,
                    confirmButtonText: Content.getValue("close_button_text"),
                    customClass: {
                        confirmButton: 'standard-confirm-button'
                    }
                });

            }
            else {
                const result = await res.json();
                const directToDiscordCommunity = result.directToDiscordCommunity;
                if (result.token) {
                    Backend.setUserDataFromJwt(result.token);
                }
                if (LoginDiscord.isSetToRedirectToDiscordCommunityAfterAuth() || 
                    directToDiscordCommunity) {
                    LoginDiscord.setToRedirectToDiscordCommunity();
                }
                LoginDiscord.redirectToHomepage();
            }

        });
    }

}

export default LoginDiscord;