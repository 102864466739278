import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Typography } from '@mui/material';

function BasicMenu({ anchorEl, open, handleClick, handleClose, children, items = [] }) {
    return (
        <div>
            <button className="hidden-button" onClick={handleClick}>
                {children}
            </button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: '#222337',
                        borderRadius: '0',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        padding: '0px',
                        position: 'absolute',
                        top: '50px', 
                        right: '10px',
                    },
                }}
            >
                {items.map((item) => (
                    <MenuItem
                        className="dropdown-item"
                        key={item.name}
                        sx={{
                            color: '#ddd',
                            position: 'relative',
                            '&:hover': {
                                backgroundColor: '#555',
                            },
                            '&::after': {
                                content: '""',
                                position: 'absolute',
                                bottom: 0,
                                left: '50%',
                                width: 0,
                                height: '0.5px',
                                backgroundColor: 'white',
                                transform: 'translateX(-50%)',
                                transition:
                                    'width 0.3s ease-in-out, left 0.3s ease-in-out, transform 0.3s ease-in-out',
                            },
                            '&:hover::after': {
                                width: '100%',
                            },
                            textAlign: 'right',
                            width: '100%',
                            padding: '0px 2px 2px 2px',
                        }}
                        onClick={() => handleClose(item.to)}
                    >
                        <Typography sx={{ fontSize: 14, width: '100%', paddingRight: '2px' }} variant="body1">
                            {item.text}
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

export default BasicMenu;