import React, { useEffect, useRef, useState } from 'react';
import Content from 'utils/Content';
import { RedirectWithLang } from 'utils/RedirectWithLang.js';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import TopOfferHomepageCard from './Top_Offer_Homepage_Card';
import ApiFacade from 'ApiFacade.js';
import JWTUtils from 'utils/JWT_Utils.js';
import Platform from 'utils/Platform';
import { Spinner } from 'react-spinners-css';
import Formatting from 'utils/Formatting';
import Images from 'utils/Images';
import UserData from 'User_Data.js';

const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;

const clickthroughSourceUIPage = configuration.clickthrough_source_ui_page;


function TopOfferHomepageList() {
  const scrollRefItems = useRef(null);
  const scrollRefItemsWithDate = useRef(null);
  const [items, setItems] = useState(null);
  const [itemsWithDate, setItemsWithDate] = useState(null);
  const [authorized, setAuthorized] = useState(false);

  const handleScroll = (scrollOffset, ref) => {
    if (ref.current) {
      ref.current.scrollBy({
        left: scrollOffset,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    let platform = Platform.getPlatformName();
    let preferredLanguage = Content.getPreferredLanguage();
    let currentCountry = JWTUtils.getJWTCountry();

    setAuthorized(ApiFacade.isLoggedIn());
    const req = ApiFacade.top12Offers(preferredLanguage, currentCountry, platform);
    req.then( (res) => {
      let result = res.response;
      setItems(result?.offers.filter(item => item.position < 1000000));
      setItemsWithDate(result?.offers.filter(item => (item.position || 1000000) >= 1000000 && item.ActivationDate !== null && item.ActivationDate !== undefined));
    });

  }, []);

  return (
    <>
      <div className={`top-offers-homepage-container ${authorized ? 'top-offers-homepage-container-mx-width' : 'gradient-bg'}`}>
        {items?.length > 0 ?
          <div className="top-offers-homepage-header">
            <h3 className="top-offers-homepage-name">
              {Formatting.format(Content.getValue("earn_from_top_12"), ["(offers)"], [items?.length || ''])}
            </h3>
            <RedirectWithLang
              to={configuration.paths.top_12_offers}
              className="top-offers-homepage-btn hidden-button "
              text={Content.getValue('full_view')}
              isButton={true}
            >
              <KeyboardArrowRightIcon fontSize='large' />
            </RedirectWithLang>
          </div> : <></>}
          <ul ref={scrollRefItems} className={`top-offers-homepage-list ${items ? "margin-bottom-50" : ''}`}>        {items ?
          items.map(item => <TopOfferHomepageCard key={item.ID} offer={{ ...item, authorized }} sourceUIPage={clickthroughSourceUIPage.top_12_homepage} />)
          : <div className='flex justify-center items-center w-full'>
            <Spinner color="#6BC6F5" />
          </div>
        }
      </ul>
        {(items?.length > 3) ?
        <>
            <button className='hidden-button top-offers-homepage-scroll-left top-25' onClick={() => handleScroll(-400, scrollRefItems)}>
            <img width={20} height={20} src={Images.imageURL('offers/scroll-left.png')} alt="ScrollLeft" />
          </button>
            <button className='hidden-button top-offers-homepage-scroll-right top-25' onClick={() => handleScroll(220, scrollRefItems)}>
            <img width={20} height={20} src={Images.imageURL('offers/scroll-right.png')} alt="ScrollLeft" />
          </button>
        </> : <></>
      }
        {(itemsWithDate && itemsWithDate?.length > 0) ?
          <div className={`top-offers-homepage-container-new ${authorized && 'user'}`}>
            <div className="top-offers-homepage-header">
              <h3 className="top-offers-homepage-name">
                {Content.getValue("latest_drops")}
              </h3>
              <RedirectWithLang
                to={configuration.paths.top_12_offers}
                className="top-offers-homepage-btn hidden-button "
                text={Content.getValue('full_view')}
                isButton={true}
                onClick={() => UserData.setItem(userDataKeys.new_offers, 1)}
              >
                <KeyboardArrowRightIcon fontSize='large' />
              </RedirectWithLang>
            </div>
            <ul ref={scrollRefItemsWithDate} className='top-offers-homepage-list second'>
              {itemsWithDate.map(item => <TopOfferHomepageCard key={item.ID} offer={{ ...item, authorized }} sourceUIPage={clickthroughSourceUIPage.latest_drops_homepage} />)}
            </ul>
            {(itemsWithDate?.length > 7) ?
              <>
                <button className='hidden-button top-offers-homepage-scroll-left bottom-35' onClick={() => handleScroll(-450, scrollRefItemsWithDate)}>
                  <img width={20} height={20} src={Images.imageURL('offers/scroll-left.png')} alt="ScrollLeft" />
                </button>
                <button className='hidden-button top-offers-homepage-scroll-right bottom-35' onClick={() => handleScroll(220, scrollRefItemsWithDate)}>
                  <img width={20} height={20} src={Images.imageURL('offers/scroll-right.png')} alt="ScrollLeft" />
                </button>
              </> : <></>
            }
          </div>
          : <></>
        }
      </div>
    </>
  );
}

export default TopOfferHomepageList;