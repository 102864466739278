import React from 'react';
import Backend from 'Backend.js';
import ApiFacade from 'ApiFacade.js';
import Offers from 'utils/Offers.js';
import Platform from 'utils/Platform.js';
import IncompleteOffer from 'components/offers/Incomplete_Offer.js';
import OfferRecommender from 'components/offers/Offer_Recommender.js';
import UserData from 'User_Data.js';
import Content from 'utils/Content.js';


let thisComponent;

const configuration = require('configuration.json');

const userDataKeys = configuration.user_data_keys;
const incompleteOfferModes = configuration.incomplete_offer_modes;
const recommendedOfferStatuses = configuration.recommended_offer_statuses;

class RecommendedOfferDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            isRestricted: false,
            recommenderDetails: null,
            recommendedOffer: null,
            alternativeOffer: null,
            offerAvailable: null,
            offerStatus: null
        };
        thisComponent = this;
        this.containerRef = React.createRef();
    }

    scrollToContainer() {
        if (this.containerRef.current) {
            const headerElement = document.querySelector('.content-page-header');
            const headerHeight = headerElement ? headerElement.offsetHeight : 0;
            
            const elementPosition = this.containerRef.current.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.scrollY - headerHeight;
            
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    }

    componentDidMount() {
        let recommendedOffer = UserData.getItem(userDataKeys.recommended_offer);
        let offerRecommender = UserData.getItem(userDataKeys.offer_recommender);
        
        this.sourceUIPage = Offers.getClickthroughSourceFromSharingSource();

        if (recommendedOffer !== null && offerRecommender !== null && !ApiFacade.isLoggedIn()) {
            let platform = Platform.getPlatformName();

            const req = ApiFacade.recommendedOffer(recommendedOffer, offerRecommender, platform);
            req.then(function (res) {
                if (res.ok){
                    let result = res.response;

                    thisComponent.setState({
                        isLoaded: true,
                        recommendedOffer: result.recommendedOffer,
                        alternativeOffer: result.alternativeOffer,
                        recommenderDetails: result.recommenderDetails,
                        offerAvailable: result.offerAvailable,
                        offerStatus: result.offerStatus
                    }, () => {
                        thisComponent.scrollToContainer();
                    });
                } else if (res.unauthorized){
                    thisComponent.setState({
                        isLoaded: true,
                        isRestricted: true
                    });
                } else {
                    thisComponent.setState({
                        isLoaded: true,
                        error: res.error
                    });
                } 
            },
            (error) => {
                thisComponent.setState({
                    isLoaded: true,
                    error
                });
            }
            )
        }
    }

    render() {
        const { error, isLoaded, recommendedOffer, alternativeOffer, recommenderDetails, offerAvailable, offerStatus } = this.state;
            if (error) {
                return <div className="error-message red-error">{Backend.loadBackendMessages().errorMessage}</div>;
            } else if (!isLoaded) {
                return <></>
            } else {
                if (offerAvailable === false) {
                    if (alternativeOffer !== null) {
                        UserData.setItem(userDataKeys.recommended_offer, alternativeOffer.uniqueID);
                    }
                    else {
                        UserData.removeItem(userDataKeys.recommended_offer);
                        UserData.removeItem(userDataKeys.offer_recommender);
                    }
                }
                const sourceUIPage = this.sourceUIPage;
                return (
                        (recommendedOffer !== null && recommendedOffer?.ID) &&
                        (offerAvailable || (offerStatus !== recommendedOfferStatuses.does_not_exist && alternativeOffer !== null && alternativeOffer?.ID ))) ?
                    (
                        <section className="recommended-offer-container" ref={this.containerRef}>
                            <div className="recommended-offer-title">{offerAvailable ? Content.getValue("recommended_offer_title") : Content.getValue("recommended_offer_title_not_available")}</div>
                            <IncompleteOffer offer={recommendedOffer} mode={(offerAvailable) ? incompleteOfferModes.recommended_offer : incompleteOfferModes.unavailable_offer} availabilityStatus={Content.getValue("recommended_offer_status_messages")[offerStatus]} key={`_offer-recommended-${recommendedOffer?.ID}_${Date.now()}`} sourceUIPage={sourceUIPage} />
                            {
                                (recommenderDetails !== null && recommenderDetails?.username && recommenderDetails?.earned && recommenderDetails?.joinedAgo) ?
                                <OfferRecommender recommenderDetails={recommenderDetails}  key={`offer-recommender-${recommenderDetails.username}_${Date.now()}`}/> :
                                (null)
                            }
                            {
                                (offerAvailable === false && alternativeOffer !== null) ? (
                                    <>
                                        <div className="recommended-offer-title alternative">{Content.getValue("recommended_offer_title_instead")}</div>
                                        <IncompleteOffer offer={alternativeOffer} mode={incompleteOfferModes.recommended_offer} key={`_offer-recommended-${recommendedOffer ? recommendedOffer.ID : 0}_${Date.now()}`} sourceUIPage={sourceUIPage} />
                                    </>
                                    ) : (null)
                            }
                        </section>
                    ) : (null);
            }
    }

}

export default RecommendedOfferDetails;