import React from 'react';
import ApiFacade from 'ApiFacade.js';
import IncompleteOffersList from 'components/offers/Incomplete_Offers_List.js';
import CompleteOffersList from 'components/offers/Completed_Offers_List.js';
import ContentPageHeader from "components/sections/Content_Page_Header.js";
import MetaTags from 'components/seo/Meta_Tags.js';
import Footer from 'components/sections/Footer.js';
import NewsTrack from 'components/live_news/News_Track.js';
import CashbackOffersList from 'components/offers/Cashback_Offers_List.js';
import UserData from 'User_Data.js';
import Content from 'utils/Content.js';
import { Mixpanel } from 'utils/User_Events_Util.js';

const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;
const playgroundModes = configuration.playground_modes;
const mixpanelEvents = configuration.mixpanel_events;
const pages = configuration.pages;

let thisComponent;

class Playground extends React.Component {

    constructor(props) {
        super(props);
        this.openEarningList = UserData.getItem(userDataKeys.open_earning_list);

        const mode = this.openEarningList ?
            playgroundModes.in_progress :
            this.category ?
                playgroundModes.cashback :
                playgroundModes.earn_more;

        this.state = {
            mode,
            offer: null
        };
        thisComponent = this;
    }

    customEventListener(event) {
        const { detail: offer } = event;
        thisComponent.setState(prev => ({ ...prev, offer }));
    }

    componentDidMount() {
        this.openEarningList && UserData.removeItem(userDataKeys.open_earning_list);
        window.addEventListener('openOfferDetails', thisComponent.customEventListener);
        Mixpanel.trackByArgs(mixpanelEvents.page_view, { page_name: pages.playground });
    }

    componentWillUnmount() {
        window.removeEventListener('openOfferDetails', thisComponent.customEventListener);
        UserData.removeItem(userDataKeys.offer_events);
        UserData.removeItem(userDataKeys.offer_wall_visit_id);
        UserData.removeItem(userDataKeys.offer_wall_visit_start);
    }

    toggleMode(newMode) {
        const { mode } = thisComponent.state;
        if (newMode === mode) return;

        thisComponent.setState({
            mode: newMode
        });
        let incompleteTab = document.getElementById("incomplete-tab");
        let completeTab = document.getElementById("complete-tab");
        let cashbackTab = document.getElementById("cashback-tab");
        if (newMode === playgroundModes.earn_more) {
            incompleteTab.className = "offers-section-child marked desktop";
            cashbackTab.className = "offers-section-child desktop";
            if (completeTab) completeTab.className = cashbackTab.className;
        }
        else if (newMode === playgroundModes.cashback) {
            cashbackTab.className = "offers-section-child marked desktop";
            incompleteTab.className = "offers-section-child desktop";
            if (completeTab) completeTab.className = incompleteTab.className;
        }
        else {
            completeTab.className = "offers-section-child marked desktop";
            cashbackTab.className = incompleteTab.className = "offers-section-child desktop";
        }
    }

    render() {

        const { mode } = thisComponent.state;

        return (
            <>
                <MetaTags
                    title={Content.getValue("meta_content").playground.title}
                    description={Content.getValue("meta_content").playground.description}
                    url={Content.getValue("meta_content").playground.url}
                />
                <div id="main">
                    <div id="content" className="content-playground">

                        <ContentPageHeader />
                        <NewsTrack />
                        <section className="content-page-section h-full" onClick={() => { var focusedApps = document.getElementsByClassName('app-wrapper focused'); if (focusedApps.length === 1) focusedApps[0].className = 'app-wrapper'; }}>
                            <div>
                                <div className="playground-heading">
                                    <div className="title-container">
                                        <h1 className="page-title desktop" id="playground-page-title">{Content.getValue('menu_title_playground')}</h1>
                                        <div className="playground-description desktop">{Content.getValue("playground_description")}</div>
                                    </div>
                                </div>
                                <div className="page-container desktop">
                                    <div className="offers-section-list desktop">
                                        <div className={`offers-section-child desktop ${mode===playgroundModes.earn_more ? "marked" : ""}`} onClick={() => thisComponent.toggleMode(playgroundModes.earn_more)} id="incomplete-tab">{Content.getValue("offers_subtitle")}<div className="highlight desktop"></div></div>
                                        <div className={`offers-section-child desktop ${mode===playgroundModes.cashback ? "marked" : ""}`} onClick={() => thisComponent.toggleMode(playgroundModes.cashback)} id="cashback-tab">{Content.getValue("cashback_subtitle")}<div className="highlight desktop"></div></div>
                                        {
                                            ApiFacade.isLoggedIn() ?
                                                (
                                                    <div className={`offers-section-child desktop ${mode === playgroundModes.in_progress ? "marked" : ""}`} onClick={() => thisComponent.toggleMode(playgroundModes.in_progress)} id="complete-tab">{Content.getValue("in_progress_subtitle")}<div className="highlight desktop"></div></div>
                                                ) :
                                                (null)
                                        }
                                    </div>

                                    {
                                        (mode === playgroundModes.in_progress || UserData.getItem(userDataKeys.open_earning_list)) ?
                                            (<CompleteOffersList />) :
                                            (mode === playgroundModes.cashback) ?
                                                (<CashbackOffersList />) :
                                                (<IncompleteOffersList />)
                                    }
                                </div>
                            </div>
                        </section>
                    </div>
                    <>
                        <div className="playground-footer mobile">
                            <Footer /> 
                        </div>
                        <div className="playground-footer desktop padding-top-30">
                            <Footer /> 
                        </div>
                    </>
                </div>
            </>
        );
    }
}

export default Playground;