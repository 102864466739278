import React, { useEffect, useState } from 'react';
import ContentPageHeader from "components/sections/Content_Page_Header.js";
import PaymentMethodsList from 'components/payment_methods/Payment_Methods_List.js';
import Footer from 'components/sections/Footer.js';
import NewsTrack from 'components/live_news/News_Track.js';
import Content from 'utils/Content.js';
import { Mixpanel } from "utils/User_Events_Util.js";
import WithdrawalCalculator from 'components/payouts/Withdrawal_Calculator.js';
import Payments from 'utils/Payments.js';
import Images from 'utils/Images.js';
import Modal from 'utils/Modal';
import PixPaymentForm from 'components/payment_methods/Pix_Payment_Form.js';
import ApiFacade from 'ApiFacade.js';
import Formatting from 'utils/Formatting.js';
import PopupModal from 'components/modals/PopupModal';
import PhoneVerificationPopup from 'popups/PhoneVerificationPopup';

import 'design/payment_methods.css';

const configuration = require('configuration.json');

const paymentMethodTypes = configuration.payment_method_types;

const mixpanelEvents = configuration.mixpanel_events;
const pages = configuration.pages;

const Cashout = () =>  {

    const defaultPaymentMethod = Payments.defaultPaymentMethod();
  
    const [openWithdrawalPopup, setOpenWithdrawalPopup] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
    const [phoneVerificationRequered, setPhoneVerificationRequered] = useState(false);
    const [coins, setCoins] = useState(0.00);
    const [treatCoinValue, setTreatCoinValue] = useState(0.00);
    const [showPhoneVerificationPopup, setShowPhoneVerificationPopup] = useState(false);
    const [phoneVerificationSuccessful, setPhoneVerificationSuccessful] = useState(false);

    useEffect(() => {
        Mixpanel.trackByArgs(mixpanelEvents.page_view, { page_name: pages.cashout });
        getClientCoins();
    }, [])

    const getClientCoins = async () => {
        try{
            const res = await ApiFacade.myCoins();
        
            if (res?.ok){
                const {coins, treatCoinValue, phoneNumberVerified} = res.response;
                setCoins(coins);
                setTreatCoinValue(treatCoinValue);
                setPhoneVerificationRequered(!phoneNumberVerified)
            }
    
        }catch{
            setCoins(0.00);
            setTreatCoinValue(0.00);
        }
    
    }


    const onWithdrawalSubmit = obj => {
        obj.mixpanelObject.withdraw_type = selectedPaymentMethod;
        obj.platform = selectedPaymentMethod;

        setOpenWithdrawalPopup(false);

        if(selectedPaymentMethod == paymentMethodTypes.pix){
            Modal.open(
                <PixPaymentForm 
                    onSubmit={(e) => {Modal.close(); Payments.processWithdrawal({...obj, ...e})}} 
                    onGoBack={() => {Modal.close(); onOpenWithdrawalPopup()}}
                />,{
                hideCloseBtn: true,
                width: 340
            })
        }else{
            Payments.processWithdrawal(obj);
        }
    }

    const onOpenWithdrawalPopup = () => {
        if(phoneVerificationRequered){
            setShowPhoneVerificationPopup(true);
        }else{
            setOpenWithdrawalPopup(true)
        }
    }

    return (
        <>
            
            <div id="main">
                <div id="content">

                    <ContentPageHeader />
                    <NewsTrack />
                    <section className="content-page-section">
                        <div>
                            <div className="title-container">
                                <h1 className="page-title">{Content.getValue('menu_title_cashout')}</h1>
                            </div>
                            <div className="user-balance bg-gradient-1">
                                <div className="flex">
                                    <span className="coins-balance">{Formatting.formatTreatCoinAmount(coins)}</span>
                                    <span className="color-grey padding-4">=</span>
                                    <span className="color-grey">{Formatting.currencySymbol()} {(coins * treatCoinValue).toFixed(2)}</span>
                                </div>
                                <div>{Content.getValue("treat_coins_available")}</div>
                            </div>
                            <p className='cashout-description'>{Content.getValue("choose_your_way_to_get_paid")}</p>
                            <div className="page-container">
                                <PaymentMethodsList onChange={setSelectedPaymentMethod} defaultPaymentMethod={defaultPaymentMethod}/>
                                <div className="withdraw-buttons">
                                    <button disabled={!selectedPaymentMethod}
                                        onClick={onOpenWithdrawalPopup}
                                        className={`start-withdraw-button ${selectedPaymentMethod ? '' : 'disabled-btn'}`}
                                    >
                                        {Content.getValue("withdraw_button_text")}
                                    </button>
                                    <button className='how-does-it-work-hint hidden-button' onClick={() => Payments.showHint(selectedPaymentMethod || defaultPaymentMethod)}>
                                        {Content.getValue("payment_methods_list_hint")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>

            {openWithdrawalPopup && (
                <div id="withdrawalPopup">
                    <div id="popup-content" className="bg-white">
                        <section className='h-full'>
                            <div>
                                <div className="page-container" onClick={() => {
                                    if (document.getElementById('payment-method-cta-div') !== null) document.getElementById('content').removeChild(document.getElementById('payment-method-cta-div'));
                                }}>
                                    {/* <UseTreatCoinsWithdrawal onClose={() => setOpenWithdrawalPopup(false)} /> */}
                                    <div className='use-treat-coins-withdrawal desktop'>
                                        <button className="hidden-button">
                                            <img
                                                src={Images.imageURL('close-welcome.png')}
                                                alt="Close withdrawal" width="24" height="24"
                                                className="close-withdrawal desktop"
                                                onClick={() => setOpenWithdrawalPopup(false)}
                                            />
                                        </button>
                                        <div className="withdraw-text-container desktop">
                                            <span className="withdraw-text desktop text-gradient-2">{Content.getValue("withdraw_label")}</span>
                                        </div>
                                        <WithdrawalCalculator onSubmit={onWithdrawalSubmit}/>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            )}

            <PopupModal 
                isOpened={showPhoneVerificationPopup} 
                closeModal={() => {
                        if (phoneVerificationSuccessful) {
                            setOpenWithdrawalPopup(true);
                            setPhoneVerificationRequered(false);
                            setShowPhoneVerificationPopup(false); 
                        } 
                        else {
                            setShowPhoneVerificationPopup(false);
                        }
                    }
                }
                className='border-rounded max-w-600 overflow-none flex-row-centered'
            >
                <PhoneVerificationPopup 
                    setPhoneVerificationSuccessful={setPhoneVerificationSuccessful}
                    onComplete={()=>{
                        setOpenWithdrawalPopup(true);
                        setPhoneVerificationRequered(false);
                        setShowPhoneVerificationPopup(false);
                    }}
                />
            </PopupModal>
        </>
    );


}

export default Cashout;