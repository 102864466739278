import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ContentPageHeader from 'components/sections/Content_Page_Header.js';
import Footer from 'components/sections/Footer.js';
import Top12OfferPageCard from 'components/top_12_offers/Top_12_Offer_Page_Card.js';
import { useEffect, useState } from 'react';
import { RedirectWithLang } from 'utils/RedirectWithLang.js';
import { Mixpanel } from "utils/User_Events_Util.js";
import ApiFacade from '../ApiFacade';
import Content from '../utils/Content';
import JWTUtils from '../utils/JWT_Utils';
import Platform from '../utils/Platform';

const configuration = require('configuration.json');
const routerBackValue = configuration.router_back_value;
const mixpanelEvents = configuration.mixpanel_events;
const pages = configuration.pages;

function MyOffers() {
    const [recommendedOffers, setRecommendedOffers] = useState(null);
    const [startedOffers, setStartedOffers] = useState(null);

    useEffect(() => {
        let platform = Platform.getPlatformName();
        let language = Content.getPreferredLanguage();
        let country = JWTUtils.getJWTCountry();

        const req = ApiFacade.myOffers({ country, language, platform });
        req.then((res) => {
            if (res)
                setStartedOffers(res?.response?.offers.filter(offer => (offer.recommended == 0 && offer.started === 1)));
                setRecommendedOffers(res?.response?.offers.filter(offer => (offer.recommended == 1 && offer.started === 0)));
        });
        const cards = document.querySelectorAll('.top-12-offer-page-card');

        let maxHeight = 0;
        cards.forEach(card => {
            const cardHeight = card.getBoundingClientRect().height;
            maxHeight = Math.max(maxHeight, cardHeight);
        });

        cards.forEach(card => {
            card.style.minHeight = `${maxHeight}px`;
        });
    }, []);

    useEffect(() => {
        Mixpanel.trackByArgs(mixpanelEvents.page_view, { page_name: pages.top_12_offers });
    }, []);

    return (
        <>
            <div id="main">
                <div id="content">
                    <ContentPageHeader />
                    <section className="content-page-section">
                        <div className="top-12-offers-page-container">
                            {(recommendedOffers?.length === 0 && startedOffers?.length === 0) ?
                                <p className='top-offers-homepage-name no-offers'>{Content.getValue('my_offer_nothing_here')}</p>
                                : <></>}
                            <div className="top-offers-homepage-header">
                                <div className='top-offers-homepage-header-left max-w-half'>
                                    {startedOffers?.length > 0 ?
                                        <h3 className='top-offers-homepage-name margin-bottom-10'>{Content.getValue('my_offers_recommended_label')}</h3>
                                        : <></>}
                                </div>
                                <RedirectWithLang
                                    to={routerBackValue}
                                    className="top-offers-homepage-btn hidden-button "
                                    isButton={true}
                                >
                                    <>
                                        <KeyboardArrowLeftIcon fontSize='large' />
                                        {Content.getValue('back')}</>
                                </RedirectWithLang>
                            </div>
                            <ul className="top-offers-page-list margin-bottom-10">
                                {startedOffers?.length > 0 ?
                                    startedOffers.map((i, idx) => <Top12OfferPageCard offer={i} index={idx + 1} key={`top12offerPagecardkey-${idx}`} />)
                                    : <></>}
                            </ul>
                            {recommendedOffers?.length > 0 ?
                                <>
                                    <div className="top-offers-homepage-header">
                                        <div className='top-offers-homepage-header-left max-w-half margin-bottom-10'>
                                            <h3 className='top-offers-homepage-name'>{Content.getValue('how_to_earn')}</h3>
                                        </div>
                                    </div>
                                    <ul className="top-offers-page-list">
                                        {recommendedOffers.map((i, idx) => <Top12OfferPageCard offer={i} index={idx + 1} key={`top12offerPagecardkey-${idx}`} />)}
                                    </ul>
                                </>
                                : <></>}
                        </div>
                    </section>
                </div>
                <Footer />

            </div>
        </>
    );
}

export default MyOffers;