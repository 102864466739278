import Formatting from 'utils/Formatting.js';
import Images from 'utils/Images.js'
import Content from 'utils/Content.js';

const OfferRecommender = ({ recommenderDetails }) => {
    return (
        <div className="recommender-details">
            <div className="recommender-details-text-container">
                <img 
                    className="recommender-details-icon" 
                    alt="Recommender username" 
                    width="14" 
                    height="auto" 
                    src={Images.imageURL("recommender/username.png")} 
                />
                <div className="recommender-details-text">
                    {Content.getValue("recommended_by")} {recommenderDetails.username}
                </div>
            </div>

            <div className="recommender-details-text-container">
                <img 
                    className="recommender-details-icon" 
                    alt="Recommender earned" 
                    width="14" 
                    height="auto" 
                    src={Images.imageURL("recommender/earned.png")} 
                />
                <div className="recommender-details-text">
                    {Formatting.format(
                        Content.getValue("recommender_treatcoins_earned"), 
                        ["(earned)"], 
                        [recommenderDetails.earned]
                    )}
                </div>
            </div>

            <div className="recommender-details-text-container">
                <img 
                    className="recommender-details-icon" 
                    alt="Recommender joined" 
                    width="14" 
                    height="auto" 
                    src={Images.imageURL("recommender/joined.png")} 
                />
                <div className="recommender-details-text">
                    {Formatting.format(
                        Content.getValue("recommender_joined"), 
                        ["(joinedAgo)"], 
                        [recommenderDetails.joinedAgo]
                    )}
                </div>
            </div>
        </div>
    );
};

export default OfferRecommender;
