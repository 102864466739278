import CompletedLayout from 'components/offers/Completed_Layout.js';
import Content from 'utils/Content.js';
import Images from 'utils/Images.js';
import Joining from 'utils/Joining.js';
import Formatting from 'utils/Formatting.js';
import { useContext } from 'react';
import { OfferPopupContext } from 'context/Offer_Popup_Context.js';
import OfferReportStatus from 'components/offers/Offer_Report_Status';
import ApiFacade from 'ApiFacade.js';
import { useNavigate } from 'react-router-dom';

const configuration = require('configuration.json');
const offerMainType = configuration.offer_main_type;

const REPORT_COMPLETED_STATUS = 4;

const ReferralEarnings = ({ idx, value }) => {  
    return (
        <div className='top-12-offer-page-card-multistep-ref'>
            {value ?
                <>
                    <p className='top-12-offer-page-card-step-name white-i w-44  text-center-i'>{Content.getValue('get')}</p>
                    <img width='20' height='20' src={Images.imageURL('offers/stats-header-money-icon-new.png')} alt="TreatCoin" className="top-offer-homepage-treat-coin" />
                    <p className='top-offer-homepage-usd-value font-14'>{value}</p>
                    <p className='top-12-offer-page-card-step-name top-12-offer-page-ref-earnings  text-center-i text-8-i '>{Content.getValue(`get_gen_complete_${idx}`)}</p>
                </>
                : <></>
            }
        </div>
    );
}


const MultipleStep = ({ step }) => {
    const {
        ID,
        desc,
        coins,
        treatCoinValue,
        isCompleted,
        multiple,
        coinsReferral,
        coins2ndGenReferral,
        coins3rdGenReferral,
        offer,
        isGold,
        showGold,
        isClicked,
    } = step;
    return (
        <li key={`multi-step-${offer.ID}`}
            className='top-12-offer-page-card-step' style={{ marginBottom: multiple ? 10 : 0 }} >
            <div className={`${multiple && 'multiple'}`}>
                <CompletedLayout coins={coins} treatCoinValue={treatCoinValue} isCompleted={isCompleted} />
            </div>
            <h5 className="top-12-offer-page-card-step-name">{Formatting.shortenText(desc, 35)}</h5>
            <div className="top-12-offer-page-card-step-border"></div>
            {isGold && showGold
                ? (<button className='gold-btn'>{Content.getValue(`gold_offer_cta`)}</button>)
                : ( <div className='top-12-offer-page-card-step-earnings'>
                        <div className='padding-top-14'>
                            {offer.isPercentage 
                                ? <p className="top-offer-homepage-tc-value ">{Formatting.format(Content.getValue('offer_popup_percentage_value'), ['(value)'], [coins])}</p>
                                : <>
                                <h5 className="top-12-offer-page-card-step-name white-i">{`${Content.getValue('complete_get')}`}</h5>
                                    <div>
                                        <div className="top-offer-homepage-payout">
                                            <img width={20} height={20} src={Images.imageURL('offers/stats-header-money-icon-new.png')} alt="TreatCoin" className="top-offer-homepage-treat-coin" />
                                            <p className="top-offer-homepage-tc-value">{coins.toLocaleString()}</p>
                                            <div className='top-offer-homepage-usd-value-container'>
                                                <p className="top-offer-homepage-usd-value">=  {Formatting.currencySymbol()}{(coins * treatCoinValue).toFixed(2)}</p>
                                            <p className={`top-offer-homepage-usd-desc ${isClicked ?'clicked':''}`}>{Formatting.currencyLabel()}</p>
                                            </div>
                                        </div>
                                        <p className="top-offer-homepage-tc-desc">Treat Coins</p>
                                    </div>
                                </>
                            }
                        </div>
                    {Math.floor(coinsReferral) ?
                        [
                            coinsReferral,
                            coins2ndGenReferral,
                            coins3rdGenReferral,
                        ].map((item, index) => <ReferralEarnings key={`earnigns-index-${index + ID}`} idx={index + 1} value={Formatting.floorWithConditions(+item, 1)} />) : <></>}
                    <img className='top-offer-play-icon' src={Images.imageURL('offers/icon_play-solid.png')} alt="Play Icon" />
                </div>)
            }
        </li>
    );
};

function Top12OfferPageCard({ offer, showReportStatus = false, showGold = true, sourceUIPage }) {

    const {
        description,
        topHeading,
        imageURL,
        category,
        appName,
        multiSteps,
        coins,
        coinsReferral,
        coins2ndGenReferral,
        coins3rdGenReferral,
        treatCoinValue,
        isCompleted,
        url,
        isFree,
        offerType,
        ID,
        ReportStatus,
        // ClickthroughID,
        CategoryImageURL,
        CategoryHPID,
        reviewAllowed,
        reviewRequired,
        reviewSubmitted,
        isPercentage,
        isGold,
        hasLinkedOffers,
        isClicked,
    } = offer;
    
    const navigate = useNavigate();
    const { handleOfferIsOpened } = useContext(OfferPopupContext);

    const multiStepsData = multiSteps ? JSON.parse(multiSteps) : false;
    // let possibleToSubmit = !multiSteps?.map(step => step.isCompleted).includes(false);
   
    return (
        <li className={`top-12-offer-page-card ${isGold ? "gold-box-shadow" : ""} ${isCompleted ? "offer-complete" : ""}`}>
            {isGold && (
                <span className='gold-offer-badge-wrap'>
                    <span className='gold-offer-badge'>{Content.getValue('gold_offer_badge')}</span>
                </span>
            )}
            <button className='hidden-button ' onClick={(e) => {
                e.preventDefault();
                if (e.currentTarget) {
                    if (ApiFacade.isLoggedIn()) {
                        if (!isCompleted) {
                            handleOfferIsOpened({ offer, sourceUIPage });
                        }
                    } else {
                        return Joining.showPopup(navigate);
                    }
                }
            }}>
                {topHeading && (<h4 className="top-12-offer-page-card-desc">{topHeading}</h4>)}
                <div className="top-12-offer-page-card-info">
                    <div
                        style={{ backgroundImage: `url(${imageURL})` }}
                        className='top-12-offer-page-card-image'
                        alt="Top-Offer" >
                        <CompletedLayout
                            isPercentage={isPercentage}
                            coins={coins} treatCoinValue={treatCoinValue} isCompleted={isCompleted}
                            reviewData={{
                                reviewSubmitted: Boolean(reviewSubmitted),
                                reviewAllowed: Boolean(reviewAllowed),
                                reviewRequired: Boolean(reviewRequired),
                            }}
                        />
                    </div>
                    <div className="top-12-offer-page-card-spec">
                        <div className="top-12-offer-page-card-spec-1">
                            <h4 className='top-12-offer-page-card-spec-1-name'>{Formatting.shortenText(appName, 35)}</h4>
                        </div>
                        {CategoryImageURL && CategoryHPID && !isGold && (
                        <div className="top-12-offer-page-category">
                            <img className='top-offer-homepage-category-img' src={Images.imageURL(CategoryImageURL)} alt="Category" />
                            <p className="top-offer-homepage-category-desc">{Content.getValue(`additional_offers_heading_${offerMainType[CategoryHPID]}`)}</p>
                        </div>
                        )}
                    </div>

                    <ol
                        style={{
                            listStyleType: multiStepsData ? 'number' : 'none',
                            overflow: multiStepsData ? 'scroll' : 'hidden',
                            marginLeft: '-5px',
                            pointerEvents: isCompleted ? 'none' : 'auto'
                        }}
                        className="top-12-offer-page-card-steps" >
                        {multiStepsData ?
                            multiStepsData.map((step, idx) => <MultipleStep
                                key={`step-${idx}`}
                                step={{
                                    ...step,
                                    appName,
                                    category,
                                    isFree,
                                    offerType,
                                    coinsReferral,
                                    coins2ndGenReferral,
                                    coins3rdGenReferral,
                                    multiSteps,
                                    url,
                                    ID,
                                    multiple: true, 
                                    offer,
                                    isGold,
                                    showGold,
                                    isClicked,
                                }} />)
                            :
                            <MultipleStep
                                step={{
                                    desc: description,
                                    ID,
                                    coins,
                                    treatCoinValue,
                                    appName,
                                    category,
                                    isFree,
                                    offerType,
                                    coinsReferral,
                                    coins2ndGenReferral,
                                    coins3rdGenReferral,
                                    multiSteps,
                                    url,
                                    offer,
                                    isGold,
                                    showGold,
                                    isClicked,
                                }} />
                        }
                    </ol>
                    {showReportStatus && // this is shown only on offers clicked page (not on top 12)
                        <OfferReportStatus
                            // - no report was submitted and offer compleated - disable          
                            disableBtn={(offer?.ForeignTicketID === null && offer?.ReportStatus === REPORT_COMPLETED_STATUS)} 
                            ForeignTicketID={offer?.ForeignTicketID}
                            ReportStatus={ReportStatus}
                            possibleToSubmit={true}
                            reportContentKey={ReportStatus === null ? 'not_sent' : ReportStatus} />
                    }
                    {hasLinkedOffers ?
                        <div className="top-offer-homepage-linked-offer card">
                            <img src={Images.imageURL('offers/linked-offer.png')} alt="Linked Offer" />
                            <span className="top-12-linked-offers-tooltip">{Content.getValue('has_linked_offer')}</span>
                        </div>
                        : <></>}
                </div>
            </button>
        </li>
    );
}

export default Top12OfferPageCard;